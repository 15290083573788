import React from 'react'
import { Path, useForm, UseFormReturn } from 'react-hook-form'

import type { FieldValues, UseFormProps } from 'react-hook-form'

type GetValuesResponse<Item extends FieldValues, isValid extends boolean> = {
  isValid: isValid,
  values: isValid extends true ? Item : undefined
}

export interface FieldsAPI<Item extends FieldValues> {
  form: UseFormReturn<Item>

  buildErrorMessage: (message: string) => ({ message: string })
  getValues: () => Promise<GetValuesResponse<Item, true> | GetValuesResponse<Item, false>>
  // getValues: GetValues<Item, true> | GetValues<Item, false>
}

export function useFieldsAPI<Item extends FieldValues>(options: UseFormProps<Item> = {}): FieldsAPI<Item> {
  // Validation is initially triggered on the first blur event. After that, it is triggered on every change event.
  options.mode = 'onTouched'
  // By setting shouldUnregister to true at useForm level, defaultValues will not be merged against a submission result.
  options.shouldUnregister = true

  const form = useForm<Item>(options)
  const fieldsRef = React.useRef({
    form,

    buildErrorMessage: (message: string) => ({ message }),
    getValues: async () => {
      const fields = Object.keys(form.control._fields)

      // Trigger validations
      const isValid = await form.trigger(fields as Path<Item>[])
      if (isValid) {
        return { isValid: true, values: form.getValues() as Item }
      }

      return { isValid: false, values: undefined }

    }
  } as FieldsAPI<Item>)

  return fieldsRef.current
}

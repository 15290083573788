import React from 'react'
import { FieldPath, FieldValues, useController } from 'react-hook-form'
import OutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput'

import type { BaseField } from '../fields.interface'
import type { InputBaseComponentProps } from '@mui/material/InputBase'

import { useFieldName, useFieldsController } from '../fields.hooks'
import { useFieldValidate } from '../validations/field-validate.hook'
import FieldBase from './field.base.component'
import { fieldClasses } from './field.classes'

export interface FieldTextProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends BaseField<TFieldValues, TName> {
  type?:
    | 'text'
    | 'password'
    | 'email'
    | '2fa-code'
    | 'money-amount'
    | 'number'
  inputMode?:
    | 'none'
    | 'text'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search'

  multiline?: boolean
  minRows?: number

  /**
   * Internal for number, money fields
   */
  startAdornment?: React.ReactNode
  endAdornment?: React.ReactNode
  inputComponent?: React.ElementType<InputBaseComponentProps>
  color?: OutlinedInputProps['color']
}

export default function FieldText<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  label,
  labelVariant,
  hint,
  required,
  defaultValue,
  disabled,
  hidden,
  validations,
  placeholder,
  autoComplete,
  autoFocus,
  skeleton,
  readOnly,
  onBlur,
  onClick,

  inputMode,
  multiline,
  minRows,
  type,
  inputComponent,
  startAdornment,
  endAdornment,
  color
}: FieldTextProps<TFieldValues, TName>) {
  const controller = useFieldsController()
  const fieldName = useFieldName<TName>(name)

  const validate = useFieldValidate(validations)

  const { field, fieldState } = useController<TFieldValues, TName>({
    name: fieldName,
    rules: {
      required,
      validate
    },
    defaultValue,
    disabled
  })

  const fieldType = React.useMemo(() => {
    switch (type) {
      case 'money-amount':
      case 'number':
      case 'email':
      case '2fa-code':
        return 'text'

      default:
        return type
    }
  }, [type])

  return (
    <FieldBase
      error={fieldState.error}
      hidden={hidden}
      hint={hint}
      label={label}
      labelVariant={labelVariant}
      name={field.name}
      onClick={onClick}
      required={required}
      skeleton={skeleton}>
      <OutlinedInput
        ref={field.ref}
        autoFocus={autoFocus}
        color={color}
        disabled={field.disabled || controller.disabled}
        endAdornment={endAdornment}
        inputComponent={inputComponent}
        inputMode={inputMode}
        margin={'dense'}
        minRows={minRows}
        multiline={multiline}
        name={field.name}
        onBlur={field.onBlur}
        onChange={field.onChange}
        readOnly={readOnly}
        required={required}
        size={'small'}
        startAdornment={startAdornment}
        type={hidden ? 'hidden' : fieldType}
        value={field.value || ''}
        inputProps={{
          'data-test-id': `field.${field.name}`,
          autoComplete,
          className: fieldClasses.input,
          onKeyPress: !multiline
            ? controller.onKeyDown
            : undefined,
          placeholder: field.value?.length > 0 ? '' : placeholder
        }}
        fullWidth
      />
    </FieldBase>
  )
}

/* tslint:disable */
/* eslint-disable */
/**
 * Online Incasso
 * The OI API description
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Activities
 */
export interface Activities {
    /**
     * 
     * @type {Array<Activity>}
     * @memberof Activities
     */
    'activity'?: Array<Activity> | null;
}
/**
 * 
 * @export
 * @interface Activity
 */
export interface Activity {
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    'activity_code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    'activity_description'?: string | null;
}
/**
 * 
 * @export
 * @interface AdditionalInformation
 */
export interface AdditionalInformation {
    /**
     * 
     * @type {AdditionalInformationMisc}
     * @memberof AdditionalInformation
     */
    'misc'?: AdditionalInformationMisc | null;
    /**
     * 
     * @type {AdditionalInformationIndustryQuartileAnalysis}
     * @memberof AdditionalInformation
     */
    'industry_quartile_analysis'?: AdditionalInformationIndustryQuartileAnalysis | null;
    /**
     * 
     * @type {AdditionalInformationIndustryComparison}
     * @memberof AdditionalInformation
     */
    'industry_comparison'?: AdditionalInformationIndustryComparison | null;
    /**
     * 
     * @type {AdditionalInformationPaymentExpectationsSummary}
     * @memberof AdditionalInformation
     */
    'payment_expectations_summary'?: AdditionalInformationPaymentExpectationsSummary | null;
    /**
     * 
     * @type {AdditionalInformationExtendedGroupStructure}
     * @memberof AdditionalInformation
     */
    'extended_group_structure'?: AdditionalInformationExtendedGroupStructure | null;
    /**
     * 
     * @type {AdditionalInformationAdditionalFinancials}
     * @memberof AdditionalInformation
     */
    'additional_financials'?: AdditionalInformationAdditionalFinancials | null;
    /**
     * 
     * @type {AdditionalInformationCompanyHistory}
     * @memberof AdditionalInformation
     */
    'company_history'?: AdditionalInformationCompanyHistory | null;
    /**
     * 
     * @type {AdditionalInformationKvkFilings}
     * @memberof AdditionalInformation
     */
    'kvk_filings'?: AdditionalInformationKvkFilings | null;
    /**
     * 
     * @type {TradingStyles}
     * @memberof AdditionalInformation
     */
    'trading_styles'?: TradingStyles | null;
    /**
     * 
     * @type {AdditionalInformationRatingHistory}
     * @memberof AdditionalInformation
     */
    'rating_history'?: AdditionalInformationRatingHistory | null;
    /**
     * 
     * @type {AdditionalInformationCommentaries}
     * @memberof AdditionalInformation
     */
    'commentaries'?: AdditionalInformationCommentaries | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformation
     */
    'additional_information_item'?: string | null;
}
/**
 * 
 * @export
 * @interface AdditionalInformationAdditionalFinancials
 */
export interface AdditionalInformationAdditionalFinancials {
    /**
     * 
     * @type {Array<AdditionalInformationFinancialItem>}
     * @memberof AdditionalInformationAdditionalFinancials
     */
    'financial_items'?: Array<AdditionalInformationFinancialItem> | null;
}
/**
 * 
 * @export
 * @interface AdditionalInformationBusinessGroup
 */
export interface AdditionalInformationBusinessGroup {
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationBusinessGroup
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationBusinessGroup
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface AdditionalInformationCommentaries
 */
export interface AdditionalInformationCommentaries {
    /**
     * 
     * @type {Array<AdditionalInformationCommentary>}
     * @memberof AdditionalInformationCommentaries
     */
    'commentary'?: Array<AdditionalInformationCommentary> | null;
}
/**
 * 
 * @export
 * @interface AdditionalInformationCommentary
 */
export interface AdditionalInformationCommentary {
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationCommentary
     */
    'commentary_text': string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationCommentary
     */
    'positive_or_negative': string;
}
/**
 * 
 * @export
 * @interface AdditionalInformationCommonRatingBanding
 */
export interface AdditionalInformationCommonRatingBanding {
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationCommonRatingBanding
     */
    '@value'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationCommonRatingBanding
     */
    '@description'?: string | null;
}
/**
 * 
 * @export
 * @interface AdditionalInformationCompanyHistory
 */
export interface AdditionalInformationCompanyHistory {
    /**
     * 
     * @type {Array<AdditionalInformationEvent>}
     * @memberof AdditionalInformationCompanyHistory
     */
    'event'?: Array<AdditionalInformationEvent> | null;
}
/**
 * 
 * @export
 * @interface AdditionalInformationCompanyInGroup
 */
export interface AdditionalInformationCompanyInGroup {
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationCompanyInGroup
     */
    'company_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationCompanyInGroup
     */
    'registered_number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationCompanyInGroup
     */
    'latest_annual_accounts'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationCompanyInGroup
     */
    'level'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationCompanyInGroup
     */
    'status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationCompanyInGroup
     */
    'common_rating_band'?: string | null;
    /**
     * 
     * @type {AdditionalInformationCommonRatingBanding}
     * @memberof AdditionalInformationCompanyInGroup
     */
    'common_rating_banding'?: AdditionalInformationCommonRatingBanding | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationCompanyInGroup
     */
    '@id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationCompanyInGroup
     */
    '@country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationCompanyInGroup
     */
    '@safe_number'?: string | null;
}
/**
 * 
 * @export
 * @interface AdditionalInformationDaySalesOutstanding
 */
export interface AdditionalInformationDaySalesOutstanding {
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationDaySalesOutstanding
     */
    'day_sales_outstanding_lower'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationDaySalesOutstanding
     */
    'day_sales_outstanding_median'?: string | null;
}
/**
 * 
 * @export
 * @interface AdditionalInformationEvent
 */
export interface AdditionalInformationEvent {
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationEvent
     */
    'date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationEvent
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationEvent
     */
    'previous_value'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationEvent
     */
    'current_value'?: string | null;
}
/**
 * 
 * @export
 * @interface AdditionalInformationExtendedGroupStructure
 */
export interface AdditionalInformationExtendedGroupStructure {
    /**
     * 
     * @type {AdditionalInformationGroupStructure}
     * @memberof AdditionalInformationExtendedGroupStructure
     */
    'group_structure'?: AdditionalInformationGroupStructure | null;
}
/**
 * 
 * @export
 * @interface AdditionalInformationFinancialItem
 */
export interface AdditionalInformationFinancialItem {
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationFinancialItem
     */
    'year_end_date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationFinancialItem
     */
    'consolidated_subsidiaries'?: string | null;
}
/**
 * 
 * @export
 * @interface AdditionalInformationGroupStructure
 */
export interface AdditionalInformationGroupStructure {
    /**
     * 
     * @type {Array<AdditionalInformationCompanyInGroup>}
     * @memberof AdditionalInformationGroupStructure
     */
    'company_in_group'?: Array<AdditionalInformationCompanyInGroup> | null;
}
/**
 * 
 * @export
 * @interface AdditionalInformationIndustryComparison
 */
export interface AdditionalInformationIndustryComparison {
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationIndustryComparison
     */
    'industry_average_credit_rating'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationIndustryComparison
     */
    'industry_average_credit_limit'?: string | null;
}
/**
 * 
 * @export
 * @interface AdditionalInformationIndustryQuartileAnalysis
 */
export interface AdditionalInformationIndustryQuartileAnalysis {
    /**
     * 
     * @type {AdditionalInformationPaymentExpectationDays}
     * @memberof AdditionalInformationIndustryQuartileAnalysis
     */
    'payment_expectation_days'?: AdditionalInformationPaymentExpectationDays | null;
    /**
     * 
     * @type {AdditionalInformationDaySalesOutstanding}
     * @memberof AdditionalInformationIndustryQuartileAnalysis
     */
    'day_sales_outstanding'?: AdditionalInformationDaySalesOutstanding | null;
}
/**
 * 
 * @export
 * @interface AdditionalInformationKvkFiling
 */
export interface AdditionalInformationKvkFiling {
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationKvkFiling
     */
    'filing_date': string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationKvkFiling
     */
    'filed_event'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationKvkFiling
     */
    'filing_text'?: string | null;
}
/**
 * 
 * @export
 * @interface AdditionalInformationKvkFilings
 */
export interface AdditionalInformationKvkFilings {
    /**
     * 
     * @type {Array<AdditionalInformationKvkFiling>}
     * @memberof AdditionalInformationKvkFilings
     */
    'kvk_filing'?: Array<AdditionalInformationKvkFiling> | null;
}
/**
 * 
 * @export
 * @interface AdditionalInformationMainOfficeIndicator
 */
export interface AdditionalInformationMainOfficeIndicator {
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationMainOfficeIndicator
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationMainOfficeIndicator
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface AdditionalInformationMisc
 */
export interface AdditionalInformationMisc {
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationMisc
     */
    'dateof_legal_form'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationMisc
     */
    'exporter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationMisc
     */
    'importer'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationMisc
     */
    'negative_rating'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationMisc
     */
    'rsin_number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationMisc
     */
    'continuance_date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationMisc
     */
    'company_age'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationMisc
     */
    'latest_accounts_date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationMisc
     */
    'number_of_searches_l12'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationMisc
     */
    'kvk_status'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdditionalInformationMisc
     */
    'kvk_economically_active'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationMisc
     */
    'branch_number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationMisc
     */
    'employee_number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationMisc
     */
    'employee_class'?: string | null;
    /**
     * 
     * @type {AdditionalInformationBusinessGroup}
     * @memberof AdditionalInformationMisc
     */
    'business_group'?: AdditionalInformationBusinessGroup | null;
    /**
     * 
     * @type {AdditionalInformationRegistrationType}
     * @memberof AdditionalInformationMisc
     */
    'registration_type'?: AdditionalInformationRegistrationType | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationMisc
     */
    'deposited_capital'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationMisc
     */
    'odds_of_failure'?: string | null;
    /**
     * 
     * @type {AdditionalInformationPrimarySection}
     * @memberof AdditionalInformationMisc
     */
    'primary_section'?: AdditionalInformationPrimarySection | null;
    /**
     * 
     * @type {AdditionalInformationMainOfficeIndicator}
     * @memberof AdditionalInformationMisc
     */
    'main_office_indicator'?: AdditionalInformationMainOfficeIndicator | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationMisc
     */
    'first_trading_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationMisc
     */
    'first_trading_name_date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationMisc
     */
    'manual_activity_description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationMisc
     */
    'founding_deed_date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationMisc
     */
    'statutaire_seal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationMisc
     */
    'statutaire_seal_update_date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationMisc
     */
    'registered_address_update'?: string | null;
    /**
     * 
     * @type {AdditionalInformationVsi}
     * @memberof AdditionalInformationMisc
     */
    'vsi'?: AdditionalInformationVsi | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationMisc
     */
    'registered_address_country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationMisc
     */
    'registered_country'?: string | null;
}
/**
 * 
 * @export
 * @interface AdditionalInformationPaymentExpectationDays
 */
export interface AdditionalInformationPaymentExpectationDays {
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationPaymentExpectationDays
     */
    'payment_expectations_lower'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationPaymentExpectationDays
     */
    'payment_expectations_median'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationPaymentExpectationDays
     */
    'payment_expectations_upper'?: string | null;
}
/**
 * 
 * @export
 * @interface AdditionalInformationPaymentExpectationsSummary
 */
export interface AdditionalInformationPaymentExpectationsSummary {
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationPaymentExpectationsSummary
     */
    'suspension_of_payments_mora'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationPaymentExpectationsSummary
     */
    'industry_average_payment_expectation_days'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationPaymentExpectationsSummary
     */
    'industry_average_day_sales_outstanding'?: string | null;
}
/**
 * 
 * @export
 * @interface AdditionalInformationPrimarySection
 */
export interface AdditionalInformationPrimarySection {
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationPrimarySection
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationPrimarySection
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface AdditionalInformationRating
 */
export interface AdditionalInformationRating {
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationRating
     */
    'common_description': string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationRating
     */
    'common_value': string;
    /**
     * 
     * @type {number}
     * @memberof AdditionalInformationRating
     */
    'credit_limit'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationRating
     */
    'provider_description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationRating
     */
    'provider_value'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationRating
     */
    'date': string;
}
/**
 * 
 * @export
 * @interface AdditionalInformationRatingHistory
 */
export interface AdditionalInformationRatingHistory {
    /**
     * 
     * @type {Array<AdditionalInformationRating>}
     * @memberof AdditionalInformationRatingHistory
     */
    'rating'?: Array<AdditionalInformationRating> | null;
}
/**
 * 
 * @export
 * @interface AdditionalInformationRegistrationType
 */
export interface AdditionalInformationRegistrationType {
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationRegistrationType
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationRegistrationType
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface AdditionalInformationVsi
 */
export interface AdditionalInformationVsi {
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationVsi
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationVsi
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'simple_value'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'house_number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'postal_code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'province'?: string | null;
}
/**
 * 
 * @export
 * @interface AffiliatedCompanies
 */
export interface AffiliatedCompanies {
    /**
     * 
     * @type {Array<AffiliatedCompany>}
     * @memberof AffiliatedCompanies
     */
    'affiliated_company': Array<AffiliatedCompany>;
}
/**
 * 
 * @export
 * @interface AffiliatedCompany
 */
export interface AffiliatedCompany {
    /**
     * 
     * @type {string}
     * @memberof AffiliatedCompany
     */
    '@id': string;
    /**
     * 
     * @type {string}
     * @memberof AffiliatedCompany
     */
    '@country': string;
    /**
     * 
     * @type {string}
     * @memberof AffiliatedCompany
     */
    '@safe_number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AffiliatedCompany
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AffiliatedCompany
     */
    'registration_number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AffiliatedCompany
     */
    'status'?: string | null;
    /**
     * 
     * @type {Address}
     * @memberof AffiliatedCompany
     */
    'address'?: Address | null;
}
/**
 * 
 * @export
 * @interface BalanceSheet
 */
export interface BalanceSheet {
    /**
     * 
     * @type {number}
     * @memberof BalanceSheet
     */
    'total_tangible_assets'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BalanceSheet
     */
    'investments'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BalanceSheet
     */
    'total_other_fixed_assets'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BalanceSheet
     */
    'total_fixed_assets'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BalanceSheet
     */
    'total_inventories'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BalanceSheet
     */
    'trade_receivables'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BalanceSheet
     */
    'miscellaneous_receivables'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BalanceSheet
     */
    'total_receivables'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BalanceSheet
     */
    'cash'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BalanceSheet
     */
    'total_current_assets'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BalanceSheet
     */
    'total_assets'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BalanceSheet
     */
    'miscellaneous_liabilities'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BalanceSheet
     */
    'total_current_liabilities'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BalanceSheet
     */
    'miscellaneous_liabilities_due_after1_year'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BalanceSheet
     */
    'total_long_term_liabilities'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BalanceSheet
     */
    'total_liabilities'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BalanceSheet
     */
    'called_up_share_capital'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BalanceSheet
     */
    'other_reserves'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BalanceSheet
     */
    'total_shareholders_equity'?: number | null;
}
/**
 * 
 * @export
 * @interface BasicInformation
 */
export interface BasicInformation {
    /**
     * 
     * @type {string}
     * @memberof BasicInformation
     */
    'business_name': string;
    /**
     * 
     * @type {string}
     * @memberof BasicInformation
     */
    'registered_company_name': string;
    /**
     * 
     * @type {string}
     * @memberof BasicInformation
     */
    'company_registration_number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicInformation
     */
    'vat_registration_number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicInformation
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof BasicInformation
     */
    'dateof_company_registration'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicInformation
     */
    'dateof_starting_operations'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicInformation
     */
    'legal_form'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicInformation
     */
    'company_status'?: string | null;
    /**
     * 
     * @type {MainActivity}
     * @memberof BasicInformation
     */
    'principal_activity'?: MainActivity | null;
    /**
     * 
     * @type {Address}
     * @memberof BasicInformation
     */
    'contact_address'?: Address | null;
    /**
     * 
     * @type {string}
     * @memberof BasicInformation
     */
    'contact_telephone_number'?: string | null;
}
/**
 * 
 * @export
 * @interface Cart
 */
export interface Cart {
    /**
     * 
     * @type {Array<number>}
     * @memberof Cart
     */
    'creditCases': Array<number>;
    /**
     * Temporary used, when paid will request these from CreditSafe
     * @type {Array<string>}
     * @memberof Cart
     */
    'creditCheckReferences': Array<string>;
}
/**
 * 
 * @export
 * @interface CaseAttachment
 */
export interface CaseAttachment {
    /**
     * Primary key of the recourse.
     * @type {number}
     * @memberof CaseAttachment
     */
    'id': number;
    /**
     * File name of the attachment.
     * @type {string}
     * @memberof CaseAttachment
     */
    'documentFileName': string;
}
/**
 * 
 * @export
 * @interface CaseTimelineStep
 */
export interface CaseTimelineStep {
    /**
     * 
     * @type {number}
     * @memberof CaseTimelineStep
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CaseTimelineStep
     */
    'type': CaseTimelineStepTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CaseTimelineStep
     */
    'performed'?: boolean | null;
    /**
     * Amount of the payment, if \"type\" is \"payment\".
     * @type {number}
     * @memberof CaseTimelineStep
     */
    'amount'?: number | null;
    /**
     * Title of the step
     * @type {string}
     * @memberof CaseTimelineStep
     */
    'title'?: string | null;
    /**
     * Message of the note, if \"type\" is \"note\".
     * @type {string}
     * @memberof CaseTimelineStep
     */
    'message'?: string | null;
    /**
     * User of the note, if \"type\" is \"note\".
     * @type {string}
     * @memberof CaseTimelineStep
     */
    'fromUser'?: string | null;
    /**
     * Date the step is scheduled to perform.
     * @type {string}
     * @memberof CaseTimelineStep
     */
    'date': string;
    /**
     * 
     * @type {Array<CaseTimelineStepAction>}
     * @memberof CaseTimelineStep
     */
    'actions'?: Array<CaseTimelineStepAction> | null;
}

export const CaseTimelineStepTypeEnum = {
    Note: 'note',
    Message: 'message',
    Payment: 'payment',
    Step: 'step'
} as const;

export type CaseTimelineStepTypeEnum = typeof CaseTimelineStepTypeEnum[keyof typeof CaseTimelineStepTypeEnum];

/**
 * 
 * @export
 * @interface CaseTimelineStepAction
 */
export interface CaseTimelineStepAction {
    /**
     * 
     * @type {number}
     * @memberof CaseTimelineStepAction
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CaseTimelineStepAction
     */
    'type': CaseTimelineStepActionTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CaseTimelineStepAction
     */
    'failed': boolean;
    /**
     * 
     * @type {string}
     * @memberof CaseTimelineStepAction
     */
    'unPerformableReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CaseTimelineStepAction
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CaseTimelineStepAction
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CaseTimelineStepAction
     */
    'message'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CaseTimelineStepAction
     */
    'address'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CaseTimelineStepAction
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CaseTimelineStepAction
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CaseTimelineStepAction
     */
    'mobileNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CaseTimelineStepAction
     */
    'performedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CaseTimelineStepAction
     */
    'callbackAt'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CaseTimelineStepAction
     */
    'callSuccess'?: boolean | null;
}

export const CaseTimelineStepActionTypeEnum = {
    FinishedAction: 'FinishedAction',
    SmsAction: 'SmsAction',
    ContinueAction: 'ContinueAction',
    PostAction: 'PostAction',
    PhoneAction: 'PhoneAction',
    EmailAction: 'EmailAction',
    RegisteredEmailAction: 'RegisteredEmailAction'
} as const;

export type CaseTimelineStepActionTypeEnum = typeof CaseTimelineStepActionTypeEnum[keyof typeof CaseTimelineStepActionTypeEnum];

/**
 * 
 * @export
 * @interface CheckoutComplete
 */
export interface CheckoutComplete {
    /**
     * IDs of the credit cases that are now started.
     * @type {Array<string>}
     * @memberof CheckoutComplete
     */
    'creditCaseIds': Array<string>;
    /**
     * IDs of the credit checks that are now available.
     * @type {Array<string>}
     * @memberof CheckoutComplete
     */
    'creditCheckIds': Array<string>;
}
/**
 * 
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * Name of the company.
     * @type {string}
     * @memberof Company
     */
    'companyName': string | null;
    /**
     * Street name of the company.
     * @type {string}
     * @memberof Company
     */
    'streetName': string | null;
    /**
     * House number of the company.
     * @type {string}
     * @memberof Company
     */
    'houseNumber': string | null;
    /**
     * Zipcode of the company.
     * @type {string}
     * @memberof Company
     */
    'zipcode': string | null;
    /**
     * City of the company.
     * @type {string}
     * @memberof Company
     */
    'city': string | null;
    /**
     * Country code of the company (ISO 3166-1 alpha-2).
     * @type {string}
     * @memberof Company
     */
    'countryCode': string | null;
    /**
     * CoC number of the company.
     * @type {string}
     * @memberof Company
     */
    'kvkNumber'?: string | null;
    /**
     * IBAN of the company.
     * @type {string}
     * @memberof Company
     */
    'bankAccountNumber': string | null;
    /**
     * Name of the bank account.
     * @type {string}
     * @memberof Company
     */
    'bankAccountName': string | null;
    /**
     * Contact name to use on letters.
     * @type {string}
     * @memberof Company
     */
    'letterContactName'?: string | null;
    /**
     * Email address to use on letters.
     * @type {string}
     * @memberof Company
     */
    'letterEmail'?: string | null;
    /**
     * Phone number to use on letters.
     * @type {string}
     * @memberof Company
     */
    'letterPhone'?: string | null;
    /**
     * CoC number to use on letters.
     * @type {string}
     * @memberof Company
     */
    'letterKvkNumber'?: string | null;
    /**
     * Vat number to use on letters.
     * @type {string}
     * @memberof Company
     */
    'letterVatNumber'?: string | null;
    /**
     * Company completed the signup.
     * @type {boolean}
     * @memberof Company
     */
    'signupCompleted'?: boolean | null;
}
/**
 * 
 * @export
 * @interface CompanyIdentification
 */
export interface CompanyIdentification {
    /**
     * 
     * @type {BasicInformation}
     * @memberof CompanyIdentification
     */
    'basic_information': BasicInformation;
    /**
     * 
     * @type {Activities}
     * @memberof CompanyIdentification
     */
    'activities'?: Activities | null;
}
/**
 * 
 * @export
 * @interface CompanySummary
 */
export interface CompanySummary {
    /**
     * 
     * @type {string}
     * @memberof CompanySummary
     */
    'business_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanySummary
     */
    'company_registration_number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanySummary
     */
    'company_status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanySummary
     */
    'country'?: string | null;
    /**
     * 
     * @type {CreditRating}
     * @memberof CompanySummary
     */
    'credit_rating': CreditRating;
    /**
     * 
     * @type {string}
     * @memberof CompanySummary
     */
    'latest_shareholders_equity_figure'?: string | null;
    /**
     * 
     * @type {MainActivity}
     * @memberof CompanySummary
     */
    'main_activity'?: MainActivity | null;
    /**
     * 
     * @type {string}
     * @memberof CompanySummary
     */
    'number'?: string | null;
}
/**
 * 
 * @export
 * @interface ContactInformation
 */
export interface ContactInformation {
    /**
     * 
     * @type {MainAddress}
     * @memberof ContactInformation
     */
    'main_address'?: MainAddress | null;
    /**
     * 
     * @type {OtherAddresses}
     * @memberof ContactInformation
     */
    'other_addresses'?: OtherAddresses | null;
    /**
     * 
     * @type {EmailAddresses}
     * @memberof ContactInformation
     */
    'email_addresses'?: EmailAddresses | null;
    /**
     * 
     * @type {Websites}
     * @memberof ContactInformation
     */
    'websites'?: Websites | null;
}
/**
 * 
 * @export
 * @interface CreateCreditCase
 */
export interface CreateCreditCase {
    /**
     * Required when isCompany = true.
     * @type {string}
     * @memberof CreateCreditCase
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCreditCase
     */
    'firstname'?: string | null;
    /**
     * Required when isCompany = false.
     * @type {string}
     * @memberof CreateCreditCase
     */
    'lastname'?: string | null;
    /**
     * Email of the debtor.
     * @type {string}
     * @memberof CreateCreditCase
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCreditCase
     */
    'mobileNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCreditCase
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCreditCase
     */
    'isCompany': boolean;
    /**
     * Street of the debtor.
     * @type {string}
     * @memberof CreateCreditCase
     */
    'streetName': string;
    /**
     * House number of the debtor.
     * @type {string}
     * @memberof CreateCreditCase
     */
    'houseNumber': string;
    /**
     * Zipcode of the debtor.
     * @type {string}
     * @memberof CreateCreditCase
     */
    'zipcode': string;
    /**
     * City of the debtor.
     * @type {string}
     * @memberof CreateCreditCase
     */
    'city': string;
    /**
     * Country code of the debtor (ISO 3166-1 alpha-2).
     * @type {string}
     * @memberof CreateCreditCase
     */
    'countryCode': string;
    /**
     * Language code to communicate in with the debtor.
     * @type {string}
     * @memberof CreateCreditCase
     */
    'languageCode': CreateCreditCaseLanguageCodeEnum;
    /**
     * ID of the administration this credit case belongs to.
     * @type {number}
     * @memberof CreateCreditCase
     */
    'administrationId'?: number | null;
    /**
     * Should the credit case start with a pre-incasso.
     * @type {boolean}
     * @memberof CreateCreditCase
     */
    'preIncasso'?: boolean;
    /**
     * Should the case be auto-forwarded to the selected bailiff.
     * @type {boolean}
     * @memberof CreateCreditCase
     */
    'autoForward'?: boolean;
    /**
     * Invoices for this case.
     * @type {Array<CreateCreditCaseInvoice>}
     * @memberof CreateCreditCase
     */
    'invoices': Array<CreateCreditCaseInvoice>;
}

export const CreateCreditCaseLanguageCodeEnum = {
    Nl: 'nl',
    En: 'en'
} as const;

export type CreateCreditCaseLanguageCodeEnum = typeof CreateCreditCaseLanguageCodeEnum[keyof typeof CreateCreditCaseLanguageCodeEnum];

/**
 * 
 * @export
 * @interface CreateCreditCaseInvoice
 */
export interface CreateCreditCaseInvoice {
    /**
     * Date of the invoice.
     * @type {string}
     * @memberof CreateCreditCaseInvoice
     */
    'invoiceDate': string;
    /**
     * Due date of the invoice.
     * @type {string}
     * @memberof CreateCreditCaseInvoice
     */
    'dueDate': string;
    /**
     * ID/Reference of the invoice.
     * @type {string}
     * @memberof CreateCreditCaseInvoice
     */
    'invoiceId': string;
    /**
     * Amount of the invoice including taxes.
     * @type {number}
     * @memberof CreateCreditCaseInvoice
     */
    'priceInclTax': number;
}
/**
 * 
 * @export
 * @interface CreateDebtorMessage
 */
export interface CreateDebtorMessage {
    /**
     * 
     * @type {string}
     * @memberof CreateDebtorMessage
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDebtorMessage
     */
    'email'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateInvoice
 */
export interface CreateInvoice {
    /**
     * Date of the invoice.
     * @type {string}
     * @memberof CreateInvoice
     */
    'invoiceDate': string;
    /**
     * Due date of the invoice.
     * @type {string}
     * @memberof CreateInvoice
     */
    'dueDate': string;
    /**
     * ID/Reference of the invoice.
     * @type {string}
     * @memberof CreateInvoice
     */
    'invoiceId': string;
    /**
     * Amount of the invoice including taxes.
     * @type {number}
     * @memberof CreateInvoice
     */
    'priceInclTax': number;
    /**
     * ID of the credit case this invoice belongs to.
     * @type {number}
     * @memberof CreateInvoice
     */
    'creditCaseId': number;
}
/**
 * 
 * @export
 * @interface CreditCase
 */
export interface CreditCase {
    /**
     * Primary key of the recourse.
     * @type {number}
     * @memberof CreditCase
     */
    'id': number;
    /**
     * Date time of when the recourse is created.
     * @type {string}
     * @memberof CreditCase
     */
    'createdAt': string;
    /**
     * Date time of when the recourse last updated.
     * @type {string}
     * @memberof CreditCase
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof CreditCase
     */
    'state'?: CreditCaseStateEnum;
    /**
     * 
     * @type {string}
     * @memberof CreditCase
     */
    'subState'?: CreditCaseSubStateEnum;
    /**
     * 
     * @type {number}
     * @memberof CreditCase
     */
    'invoicesPrice': number;
    /**
     * 
     * @type {number}
     * @memberof CreditCase
     */
    'casePrice': number;
    /**
     * 
     * @type {number}
     * @memberof CreditCase
     */
    'interestPrice': number;
    /**
     * 
     * @type {number}
     * @memberof CreditCase
     */
    'totalPrice': number;
    /**
     * 
     * @type {number}
     * @memberof CreditCase
     */
    'totalPriceOpen': number;
    /**
     * 
     * @type {number}
     * @memberof CreditCase
     */
    'postPaymentsPrice': number;
    /**
     * Required when isCompany = true.
     * @type {string}
     * @memberof CreditCase
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreditCase
     */
    'identificationNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreditCase
     */
    'firstname'?: string | null;
    /**
     * Required when isCompany = false.
     * @type {string}
     * @memberof CreditCase
     */
    'lastname'?: string | null;
    /**
     * Email of the debtor.
     * @type {string}
     * @memberof CreditCase
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreditCase
     */
    'mobileNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreditCase
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreditCase
     */
    'isCompany': boolean;
    /**
     * Street of the debtor.
     * @type {string}
     * @memberof CreditCase
     */
    'streetName': string;
    /**
     * House number of the debtor.
     * @type {string}
     * @memberof CreditCase
     */
    'houseNumber': string;
    /**
     * Zipcode of the debtor.
     * @type {string}
     * @memberof CreditCase
     */
    'zipcode': string;
    /**
     * City of the debtor.
     * @type {string}
     * @memberof CreditCase
     */
    'city': string;
    /**
     * Country code of the debtor (ISO 3166-1 alpha-2).
     * @type {string}
     * @memberof CreditCase
     */
    'countryCode': string;
    /**
     * Language code to communicate in with the debtor.
     * @type {string}
     * @memberof CreditCase
     */
    'languageCode': CreditCaseLanguageCodeEnum;
    /**
     * ID of the administration this credit case belongs to.
     * @type {number}
     * @memberof CreditCase
     */
    'administrationId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreditCase
     */
    'startedAt'?: string | null;
    /**
     * Should the credit case start with a pre-incasso.
     * @type {boolean}
     * @memberof CreditCase
     */
    'preIncasso'?: boolean;
    /**
     * 
     * @type {CreditCaseCreditor}
     * @memberof CreditCase
     */
    'creditor': CreditCaseCreditor;
    /**
     * 
     * @type {string}
     * @memberof CreditCase
     */
    'interestType': CreditCaseInterestTypeEnum;
    /**
     * Should the case be auto-forwarded to the selected bailiff.
     * @type {boolean}
     * @memberof CreditCase
     */
    'autoForward'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreditCase
     */
    'forwardedAt'?: string | null;
}

export const CreditCaseStateEnum = {
    Draft: 'draft',
    Paused: 'paused',
    Forwarded: 'forwarded',
    Closed: 'closed',
    Finished: 'finished',
    Paid: 'paid',
    Open: 'open'
} as const;

export type CreditCaseStateEnum = typeof CreditCaseStateEnum[keyof typeof CreditCaseStateEnum];
export const CreditCaseSubStateEnum = {
    Draft: 'draft',
    Notice: 'notice',
    SecondNotice: 'second_notice',
    FormalNotice: 'formal_notice',
    PreJudiciary: 'pre_judiciary',
    Judiciary: 'judiciary',
    Finished: 'finished'
} as const;

export type CreditCaseSubStateEnum = typeof CreditCaseSubStateEnum[keyof typeof CreditCaseSubStateEnum];
export const CreditCaseLanguageCodeEnum = {
    Nl: 'nl',
    En: 'en'
} as const;

export type CreditCaseLanguageCodeEnum = typeof CreditCaseLanguageCodeEnum[keyof typeof CreditCaseLanguageCodeEnum];
export const CreditCaseInterestTypeEnum = {
    None: 'none',
    Custom: 'custom',
    Legal: 'legal'
} as const;

export type CreditCaseInterestTypeEnum = typeof CreditCaseInterestTypeEnum[keyof typeof CreditCaseInterestTypeEnum];

/**
 * 
 * @export
 * @interface CreditCaseAction
 */
export interface CreditCaseAction {
    /**
     * 
     * @type {string}
     * @memberof CreditCaseAction
     */
    'subState': CreditCaseActionSubStateEnum;
    /**
     * 
     * @type {number}
     * @memberof CreditCaseAction
     */
    'count': number;
}

export const CreditCaseActionSubStateEnum = {
    Draft: 'draft',
    Notice: 'notice',
    SecondNotice: 'second_notice',
    FormalNotice: 'formal_notice',
    PreJudiciary: 'pre_judiciary',
    Judiciary: 'judiciary',
    Finished: 'finished'
} as const;

export type CreditCaseActionSubStateEnum = typeof CreditCaseActionSubStateEnum[keyof typeof CreditCaseActionSubStateEnum];

/**
 * 
 * @export
 * @interface CreditCaseConnection
 */
export interface CreditCaseConnection {
    /**
     * 
     * @type {PageInfoType}
     * @memberof CreditCaseConnection
     */
    'pageInfo': PageInfoType;
    /**
     * Total amount of records.
     * @type {number}
     * @memberof CreditCaseConnection
     */
    'totalCount': number;
    /**
     * Array of nodes.
     * @type {Array<CreditCase>}
     * @memberof CreditCaseConnection
     */
    'nodes': Array<CreditCase>;
}
/**
 * 
 * @export
 * @interface CreditCaseCreditor
 */
export interface CreditCaseCreditor {
    /**
     * 
     * @type {string}
     * @memberof CreditCaseCreditor
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof CreditCaseCreditor
     */
    'streetName': string;
    /**
     * 
     * @type {string}
     * @memberof CreditCaseCreditor
     */
    'houseNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreditCaseCreditor
     */
    'zipcode': string;
    /**
     * 
     * @type {string}
     * @memberof CreditCaseCreditor
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof CreditCaseCreditor
     */
    'countryCode': string;
    /**
     * 
     * @type {string}
     * @memberof CreditCaseCreditor
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreditCaseCreditor
     */
    'mobileNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreditCaseCreditor
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreditCaseCreditor
     */
    'bankAccountNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreditCaseCreditor
     */
    'bankAccountName'?: string | null;
}
/**
 * 
 * @export
 * @interface CreditCaseReference
 */
export interface CreditCaseReference {
    /**
     * Primary key of the recourse.
     * @type {number}
     * @memberof CreditCaseReference
     */
    'id': number;
    /**
     * Date time of when the recourse is created.
     * @type {string}
     * @memberof CreditCaseReference
     */
    'createdAt': string;
    /**
     * Date time of when the recourse last updated.
     * @type {string}
     * @memberof CreditCaseReference
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof CreditCaseReference
     */
    'identificationNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreditCaseReference
     */
    'state'?: CreditCaseReferenceStateEnum;
    /**
     * 
     * @type {string}
     * @memberof CreditCaseReference
     */
    'subState'?: CreditCaseReferenceSubStateEnum;
}

export const CreditCaseReferenceStateEnum = {
    Draft: 'draft',
    Paused: 'paused',
    Forwarded: 'forwarded',
    Closed: 'closed',
    Finished: 'finished',
    Paid: 'paid',
    Open: 'open'
} as const;

export type CreditCaseReferenceStateEnum = typeof CreditCaseReferenceStateEnum[keyof typeof CreditCaseReferenceStateEnum];
export const CreditCaseReferenceSubStateEnum = {
    Draft: 'draft',
    Notice: 'notice',
    SecondNotice: 'second_notice',
    FormalNotice: 'formal_notice',
    PreJudiciary: 'pre_judiciary',
    Judiciary: 'judiciary',
    Finished: 'finished'
} as const;

export type CreditCaseReferenceSubStateEnum = typeof CreditCaseReferenceSubStateEnum[keyof typeof CreditCaseReferenceSubStateEnum];

/**
 * 
 * @export
 * @interface CreditCheck
 */
export interface CreditCheck {
    /**
     * Primary key of the recourse.
     * @type {number}
     * @memberof CreditCheck
     */
    'id': number;
    /**
     * Date time of when the recourse is created.
     * @type {string}
     * @memberof CreditCheck
     */
    'createdAt': string;
    /**
     * Date time of when the recourse last updated.
     * @type {string}
     * @memberof CreditCheck
     */
    'updatedAt': string;
    /**
     * Reference of the report.
     * @type {string}
     * @memberof CreditCheck
     */
    'reference'?: string | null;
    /**
     * 
     * @type {CreditCheckReport}
     * @memberof CreditCheck
     */
    'report'?: CreditCheckReport | null;
    /**
     * Name of the business that this report is for.
     * @type {string}
     * @memberof CreditCheck
     */
    'businessName'?: string | null;
}
/**
 * 
 * @export
 * @interface CreditCheckConnection
 */
export interface CreditCheckConnection {
    /**
     * 
     * @type {PageInfoType}
     * @memberof CreditCheckConnection
     */
    'pageInfo': PageInfoType;
    /**
     * Total amount of records.
     * @type {number}
     * @memberof CreditCheckConnection
     */
    'totalCount': number;
    /**
     * Array of nodes.
     * @type {Array<CreditCheck>}
     * @memberof CreditCheckConnection
     */
    'nodes': Array<CreditCheck>;
}
/**
 * The report.
 * @export
 * @interface CreditCheckReport
 */
export interface CreditCheckReport {
    /**
     * 
     * @type {string}
     * @memberof CreditCheckReport
     */
    '@company_id': string;
    /**
     * 
     * @type {string}
     * @memberof CreditCheckReport
     */
    '@language': string;
    /**
     * 
     * @type {string}
     * @memberof CreditCheckReport
     */
    '@report_currency': string;
    /**
     * 
     * @type {CompanySummary}
     * @memberof CreditCheckReport
     */
    'company_summary': CompanySummary;
    /**
     * 
     * @type {CompanyIdentification}
     * @memberof CreditCheckReport
     */
    'company_identification': CompanyIdentification;
    /**
     * 
     * @type {string}
     * @memberof CreditCheckReport
     */
    'data_of_report'?: string | null;
    /**
     * 
     * @type {ShareCapitalStructure}
     * @memberof CreditCheckReport
     */
    'share_capital_structure'?: ShareCapitalStructure | null;
    /**
     * 
     * @type {CreditScore}
     * @memberof CreditCheckReport
     */
    'credit_score'?: CreditScore | null;
    /**
     * 
     * @type {ContactInformation}
     * @memberof CreditCheckReport
     */
    'contact_information'?: ContactInformation | null;
    /**
     * 
     * @type {Directors}
     * @memberof CreditCheckReport
     */
    'directors'?: Directors | null;
    /**
     * 
     * @type {OtherInformation}
     * @memberof CreditCheckReport
     */
    'other_information'?: OtherInformation | null;
    /**
     * 
     * @type {GroupStructure}
     * @memberof CreditCheckReport
     */
    'group_structure'?: GroupStructure | null;
    /**
     * 
     * @type {FinancialStatements}
     * @memberof CreditCheckReport
     */
    'financial_statements'?: FinancialStatements | null;
    /**
     * 
     * @type {AdditionalInformation}
     * @memberof CreditCheckReport
     */
    'additional_information'?: AdditionalInformation | null;
}
/**
 * 
 * @export
 * @interface CreditCheckReportDirector
 */
export interface CreditCheckReportDirector {
    /**
     * 
     * @type {Address}
     * @memberof CreditCheckReportDirector
     */
    'address'?: Address | null;
    /**
     * 
     * @type {string}
     * @memberof CreditCheckReportDirector
     */
    'gender'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreditCheckReportDirector
     */
    'date_of_birth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreditCheckReportDirector
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreditCheckReportDirector
     */
    'position'?: string | null;
}
/**
 * 
 * @export
 * @interface CreditCheckReportPreviousDirector
 */
export interface CreditCheckReportPreviousDirector {
    /**
     * 
     * @type {Address}
     * @memberof CreditCheckReportPreviousDirector
     */
    'address'?: Address | null;
    /**
     * 
     * @type {string}
     * @memberof CreditCheckReportPreviousDirector
     */
    'gender'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreditCheckReportPreviousDirector
     */
    'date_of_birth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreditCheckReportPreviousDirector
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreditCheckReportPreviousDirector
     */
    'position'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreditCheckReportPreviousDirector
     */
    'resignation_date'?: string | null;
}
/**
 * 
 * @export
 * @interface CreditRating
 */
export interface CreditRating {
    /**
     * 
     * @type {string}
     * @memberof CreditRating
     */
    'common_description': string;
    /**
     * 
     * @type {string}
     * @memberof CreditRating
     */
    'common_value': string;
    /**
     * 
     * @type {number}
     * @memberof CreditRating
     */
    'credit_limit'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreditRating
     */
    'provider_description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreditRating
     */
    'provider_value'?: string | null;
}
/**
 * 
 * @export
 * @interface CreditScore
 */
export interface CreditScore {
    /**
     * 
     * @type {CreditRating}
     * @memberof CreditScore
     */
    'current_credit_rating'?: CreditRating | null;
    /**
     * 
     * @type {CreditRating}
     * @memberof CreditScore
     */
    'previous_credit_rating'?: CreditRating | null;
    /**
     * 
     * @type {string}
     * @memberof CreditScore
     */
    'date_of_latest_rating_change'?: string | null;
}
/**
 * 
 * @export
 * @interface CurrentDirectors
 */
export interface CurrentDirectors {
    /**
     * 
     * @type {Array<CreditCheckReportDirector>}
     * @memberof CurrentDirectors
     */
    'director'?: Array<CreditCheckReportDirector> | null;
}
/**
 * 
 * @export
 * @interface DashboardStats
 */
export interface DashboardStats {
    /**
     * Amount of credit cases with an open status.
     * @type {number}
     * @memberof DashboardStats
     */
    'creditCasesOpen': number;
    /**
     * Credit cases with an open status.
     * @type {Array<CreditCaseReference>}
     * @memberof DashboardStats
     */
    'creditCases': Array<CreditCaseReference>;
    /**
     * Aggregated credit cases by sub state.
     * @type {Array<CreditCaseAction>}
     * @memberof DashboardStats
     */
    'actions': Array<CreditCaseAction>;
}
/**
 * 
 * @export
 * @interface DebtorCreditCase
 */
export interface DebtorCreditCase {
    /**
     * 
     * @type {string}
     * @memberof DebtorCreditCase
     */
    'state'?: DebtorCreditCaseStateEnum;
    /**
     * 
     * @type {number}
     * @memberof DebtorCreditCase
     */
    'invoicesPrice': number;
    /**
     * 
     * @type {number}
     * @memberof DebtorCreditCase
     */
    'casePrice': number;
    /**
     * 
     * @type {number}
     * @memberof DebtorCreditCase
     */
    'interestPrice': number;
    /**
     * 
     * @type {number}
     * @memberof DebtorCreditCase
     */
    'totalPrice': number;
    /**
     * 
     * @type {number}
     * @memberof DebtorCreditCase
     */
    'totalPriceOpen': number;
    /**
     * 
     * @type {number}
     * @memberof DebtorCreditCase
     */
    'postPaymentsPrice': number;
    /**
     * Required when isCompany = true.
     * @type {string}
     * @memberof DebtorCreditCase
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DebtorCreditCase
     */
    'identificationNumber': string;
    /**
     * 
     * @type {string}
     * @memberof DebtorCreditCase
     */
    'firstname'?: string | null;
    /**
     * Required when isCompany = false.
     * @type {string}
     * @memberof DebtorCreditCase
     */
    'lastname'?: string | null;
    /**
     * Email of the debtor.
     * @type {string}
     * @memberof DebtorCreditCase
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DebtorCreditCase
     */
    'mobileNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DebtorCreditCase
     */
    'phoneNumber'?: string | null;
    /**
     * Street of the debtor.
     * @type {string}
     * @memberof DebtorCreditCase
     */
    'streetName': string;
    /**
     * House number of the debtor.
     * @type {string}
     * @memberof DebtorCreditCase
     */
    'houseNumber': string;
    /**
     * Zipcode of the debtor.
     * @type {string}
     * @memberof DebtorCreditCase
     */
    'zipcode': string;
    /**
     * City of the debtor.
     * @type {string}
     * @memberof DebtorCreditCase
     */
    'city': string;
    /**
     * Country code of the debtor (ISO 3166-1 alpha-2).
     * @type {string}
     * @memberof DebtorCreditCase
     */
    'countryCode': string;
    /**
     * Should the credit case start with a pre-incasso.
     * @type {boolean}
     * @memberof DebtorCreditCase
     */
    'preIncasso'?: boolean;
    /**
     * 
     * @type {CreditCaseCreditor}
     * @memberof DebtorCreditCase
     */
    'creditor': CreditCaseCreditor;
    /**
     * 
     * @type {string}
     * @memberof DebtorCreditCase
     */
    'interestType': DebtorCreditCaseInterestTypeEnum;
    /**
     * 
     * @type {Array<Invoice>}
     * @memberof DebtorCreditCase
     */
    'invoices': Array<Invoice>;
    /**
     * 
     * @type {string}
     * @memberof DebtorCreditCase
     */
    'currency': string;
    /**
     * Timeline of the credit case.
     * @type {Array<CaseTimelineStep>}
     * @memberof DebtorCreditCase
     */
    'timeline': Array<CaseTimelineStep>;
}

export const DebtorCreditCaseStateEnum = {
    Draft: 'draft',
    Paused: 'paused',
    Forwarded: 'forwarded',
    Closed: 'closed',
    Finished: 'finished',
    Paid: 'paid',
    Open: 'open'
} as const;

export type DebtorCreditCaseStateEnum = typeof DebtorCreditCaseStateEnum[keyof typeof DebtorCreditCaseStateEnum];
export const DebtorCreditCaseInterestTypeEnum = {
    None: 'none',
    Custom: 'custom',
    Legal: 'legal'
} as const;

export type DebtorCreditCaseInterestTypeEnum = typeof DebtorCreditCaseInterestTypeEnum[keyof typeof DebtorCreditCaseInterestTypeEnum];

/**
 * 
 * @export
 * @interface Directors
 */
export interface Directors {
    /**
     * 
     * @type {CurrentDirectors}
     * @memberof Directors
     */
    'current_directors'?: CurrentDirectors | null;
    /**
     * 
     * @type {PreviousDirectors}
     * @memberof Directors
     */
    'previous_directors'?: PreviousDirectors | null;
}
/**
 * 
 * @export
 * @interface EmailAddresses
 */
export interface EmailAddresses {
    /**
     * 
     * @type {string}
     * @memberof EmailAddresses
     */
    'email_address'?: string | null;
}
/**
 * 
 * @export
 * @interface EmployeeInformation
 */
export interface EmployeeInformation {
    /**
     * 
     * @type {string}
     * @memberof EmployeeInformation
     */
    'year'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeeInformation
     */
    'number_of_employees'?: string | null;
}
/**
 * 
 * @export
 * @interface EmployeesInformation
 */
export interface EmployeesInformation {
    /**
     * 
     * @type {Array<EmployeeInformation>}
     * @memberof EmployeesInformation
     */
    'employee_information'?: Array<EmployeeInformation> | null;
}
/**
 * 
 * @export
 * @interface FinancialStatement
 */
export interface FinancialStatement {
    /**
     * 
     * @type {string}
     * @memberof FinancialStatement
     */
    'year_end_date': string;
    /**
     * 
     * @type {string}
     * @memberof FinancialStatement
     */
    'number_of_weeks': string;
    /**
     * 
     * @type {string}
     * @memberof FinancialStatement
     */
    'currency': string;
    /**
     * 
     * @type {boolean}
     * @memberof FinancialStatement
     */
    'consolidated_accounts'?: boolean | null;
    /**
     * 
     * @type {BalanceSheet}
     * @memberof FinancialStatement
     */
    'balance_sheet': BalanceSheet;
    /**
     * 
     * @type {OtherFinancials}
     * @memberof FinancialStatement
     */
    'other_financials': OtherFinancials;
    /**
     * 
     * @type {Ratios}
     * @memberof FinancialStatement
     */
    'ratios': Ratios;
    /**
     * 
     * @type {ProfitAndLoss}
     * @memberof FinancialStatement
     */
    'profit_and_loss': ProfitAndLoss;
}
/**
 * 
 * @export
 * @interface FinancialStatements
 */
export interface FinancialStatements {
    /**
     * 
     * @type {Array<FinancialStatement>}
     * @memberof FinancialStatements
     */
    'financial_statement': Array<FinancialStatement>;
}
/**
 * 
 * @export
 * @interface FindCreditCase
 */
export interface FindCreditCase {
    /**
     * Primary key of the recourse.
     * @type {number}
     * @memberof FindCreditCase
     */
    'id': number;
    /**
     * Date time of when the recourse is created.
     * @type {string}
     * @memberof FindCreditCase
     */
    'createdAt': string;
    /**
     * Date time of when the recourse last updated.
     * @type {string}
     * @memberof FindCreditCase
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof FindCreditCase
     */
    'state'?: FindCreditCaseStateEnum;
    /**
     * 
     * @type {string}
     * @memberof FindCreditCase
     */
    'subState'?: FindCreditCaseSubStateEnum;
    /**
     * 
     * @type {number}
     * @memberof FindCreditCase
     */
    'invoicesPrice': number;
    /**
     * 
     * @type {number}
     * @memberof FindCreditCase
     */
    'casePrice': number;
    /**
     * 
     * @type {number}
     * @memberof FindCreditCase
     */
    'interestPrice': number;
    /**
     * 
     * @type {number}
     * @memberof FindCreditCase
     */
    'totalPrice': number;
    /**
     * 
     * @type {number}
     * @memberof FindCreditCase
     */
    'totalPriceOpen': number;
    /**
     * 
     * @type {number}
     * @memberof FindCreditCase
     */
    'postPaymentsPrice': number;
    /**
     * Required when isCompany = true.
     * @type {string}
     * @memberof FindCreditCase
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FindCreditCase
     */
    'identificationNumber': string;
    /**
     * 
     * @type {string}
     * @memberof FindCreditCase
     */
    'firstname'?: string | null;
    /**
     * Required when isCompany = false.
     * @type {string}
     * @memberof FindCreditCase
     */
    'lastname'?: string | null;
    /**
     * Email of the debtor.
     * @type {string}
     * @memberof FindCreditCase
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FindCreditCase
     */
    'mobileNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FindCreditCase
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FindCreditCase
     */
    'isCompany': boolean;
    /**
     * Street of the debtor.
     * @type {string}
     * @memberof FindCreditCase
     */
    'streetName': string;
    /**
     * House number of the debtor.
     * @type {string}
     * @memberof FindCreditCase
     */
    'houseNumber': string;
    /**
     * Zipcode of the debtor.
     * @type {string}
     * @memberof FindCreditCase
     */
    'zipcode': string;
    /**
     * City of the debtor.
     * @type {string}
     * @memberof FindCreditCase
     */
    'city': string;
    /**
     * Country code of the debtor (ISO 3166-1 alpha-2).
     * @type {string}
     * @memberof FindCreditCase
     */
    'countryCode': string;
    /**
     * Language code to communicate in with the debtor.
     * @type {string}
     * @memberof FindCreditCase
     */
    'languageCode': FindCreditCaseLanguageCodeEnum;
    /**
     * ID of the administration this credit case belongs to.
     * @type {number}
     * @memberof FindCreditCase
     */
    'administrationId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FindCreditCase
     */
    'startedAt'?: string | null;
    /**
     * Should the credit case start with a pre-incasso.
     * @type {boolean}
     * @memberof FindCreditCase
     */
    'preIncasso'?: boolean;
    /**
     * 
     * @type {CreditCaseCreditor}
     * @memberof FindCreditCase
     */
    'creditor': CreditCaseCreditor;
    /**
     * 
     * @type {string}
     * @memberof FindCreditCase
     */
    'interestType': FindCreditCaseInterestTypeEnum;
    /**
     * Should the case be auto-forwarded to the selected bailiff.
     * @type {boolean}
     * @memberof FindCreditCase
     */
    'autoForward'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FindCreditCase
     */
    'forwardedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FindCreditCase
     */
    'bailiffName': string;
    /**
     * 
     * @type {Array<Invoice>}
     * @memberof FindCreditCase
     */
    'invoices': Array<Invoice>;
    /**
     * 
     * @type {Array<CaseAttachment>}
     * @memberof FindCreditCase
     */
    'attachments': Array<CaseAttachment>;
    /**
     * 
     * @type {string}
     * @memberof FindCreditCase
     */
    'currency': string;
}

export const FindCreditCaseStateEnum = {
    Draft: 'draft',
    Paused: 'paused',
    Forwarded: 'forwarded',
    Closed: 'closed',
    Finished: 'finished',
    Paid: 'paid',
    Open: 'open'
} as const;

export type FindCreditCaseStateEnum = typeof FindCreditCaseStateEnum[keyof typeof FindCreditCaseStateEnum];
export const FindCreditCaseSubStateEnum = {
    Draft: 'draft',
    Notice: 'notice',
    SecondNotice: 'second_notice',
    FormalNotice: 'formal_notice',
    PreJudiciary: 'pre_judiciary',
    Judiciary: 'judiciary',
    Finished: 'finished'
} as const;

export type FindCreditCaseSubStateEnum = typeof FindCreditCaseSubStateEnum[keyof typeof FindCreditCaseSubStateEnum];
export const FindCreditCaseLanguageCodeEnum = {
    Nl: 'nl',
    En: 'en'
} as const;

export type FindCreditCaseLanguageCodeEnum = typeof FindCreditCaseLanguageCodeEnum[keyof typeof FindCreditCaseLanguageCodeEnum];
export const FindCreditCaseInterestTypeEnum = {
    None: 'none',
    Custom: 'custom',
    Legal: 'legal'
} as const;

export type FindCreditCaseInterestTypeEnum = typeof FindCreditCaseInterestTypeEnum[keyof typeof FindCreditCaseInterestTypeEnum];

/**
 * 
 * @export
 * @interface GroupStructure
 */
export interface GroupStructure {
    /**
     * 
     * @type {AffiliatedCompany}
     * @memberof GroupStructure
     */
    'immediate_parent'?: AffiliatedCompany | null;
    /**
     * 
     * @type {AffiliatedCompany}
     * @memberof GroupStructure
     */
    'ultimate_parent'?: AffiliatedCompany | null;
    /**
     * 
     * @type {SubsidiaryCompanies}
     * @memberof GroupStructure
     */
    'subsidiary_companies'?: SubsidiaryCompanies | null;
    /**
     * 
     * @type {AffiliatedCompanies}
     * @memberof GroupStructure
     */
    'affiliated_companies'?: AffiliatedCompanies | null;
}
/**
 * 
 * @export
 * @interface Invoice
 */
export interface Invoice {
    /**
     * Primary key of the recourse.
     * @type {number}
     * @memberof Invoice
     */
    'id': number;
    /**
     * Date of the invoice.
     * @type {string}
     * @memberof Invoice
     */
    'invoiceDate': string;
    /**
     * Due date of the invoice.
     * @type {string}
     * @memberof Invoice
     */
    'dueDate': string;
    /**
     * ID/Reference of the invoice.
     * @type {string}
     * @memberof Invoice
     */
    'invoiceId': string;
    /**
     * Amount of the invoice including taxes.
     * @type {number}
     * @memberof Invoice
     */
    'priceInclTax': number;
    /**
     * ID of the credit case this invoice belongs to.
     * @type {number}
     * @memberof Invoice
     */
    'creditCaseId': number;
    /**
     * Currency of the invoice.
     * @type {string}
     * @memberof Invoice
     */
    'currency': string;
}
/**
 * 
 * @export
 * @interface LoginDto
 */
export interface LoginDto {
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    'client_id': string | null;
}
/**
 * 
 * @export
 * @interface LoginWithPin
 */
export interface LoginWithPin {
    /**
     * 
     * @type {string}
     * @memberof LoginWithPin
     */
    'identificationNumber': string;
    /**
     * 
     * @type {string}
     * @memberof LoginWithPin
     */
    'identificationPin': string;
}
/**
 * 
 * @export
 * @interface MainActivity
 */
export interface MainActivity {
    /**
     * 
     * @type {string}
     * @memberof MainActivity
     */
    'activity_code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MainActivity
     */
    'activity_description'?: string | null;
}
/**
 * 
 * @export
 * @interface MainAddress
 */
export interface MainAddress {
    /**
     * 
     * @type {Address}
     * @memberof MainAddress
     */
    'address'?: Address | null;
    /**
     * 
     * @type {string}
     * @memberof MainAddress
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MainAddress
     */
    'telephone'?: string | null;
}
/**
 * 
 * @export
 * @interface Me
 */
export interface Me {
    /**
     * 
     * @type {string}
     * @memberof Me
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Me
     */
    'username': string;
    /**
     * 
     * @type {boolean}
     * @memberof Me
     */
    'admin': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Me
     */
    'owner': boolean;
    /**
     * 
     * @type {Company}
     * @memberof Me
     */
    'company': Company;
}
/**
 * 
 * @export
 * @interface OtherAddresses
 */
export interface OtherAddresses {
    /**
     * 
     * @type {MainAddress}
     * @memberof OtherAddresses
     */
    'other_address'?: MainAddress | null;
}
/**
 * 
 * @export
 * @interface OtherFinancials
 */
export interface OtherFinancials {
    /**
     * 
     * @type {number}
     * @memberof OtherFinancials
     */
    'working_capital'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OtherFinancials
     */
    'net_worth'?: number | null;
}
/**
 * 
 * @export
 * @interface OtherInformation
 */
export interface OtherInformation {
    /**
     * 
     * @type {EmployeesInformation}
     * @memberof OtherInformation
     */
    'employees_information'?: EmployeesInformation | null;
}
/**
 * 
 * @export
 * @interface PageInfoType
 */
export interface PageInfoType {
    /**
     * true if paging forward and there are more records.
     * @type {boolean}
     * @memberof PageInfoType
     */
    'hasNextPage'?: boolean | null;
    /**
     * true if paging backwards and there are more records.
     * @type {boolean}
     * @memberof PageInfoType
     */
    'hasPreviousPage'?: boolean | null;
}
/**
 * 
 * @export
 * @interface PreviousDirectors
 */
export interface PreviousDirectors {
    /**
     * 
     * @type {Array<CreditCheckReportPreviousDirector>}
     * @memberof PreviousDirectors
     */
    'director'?: Array<CreditCheckReportPreviousDirector> | null;
}
/**
 * 
 * @export
 * @interface ProfitAndLoss
 */
export interface ProfitAndLoss {
    /**
     * 
     * @type {number}
     * @memberof ProfitAndLoss
     */
    'operating_costs'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProfitAndLoss
     */
    'operating_profit'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProfitAndLoss
     */
    'wages_and_salaries'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProfitAndLoss
     */
    'depreciation'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProfitAndLoss
     */
    'financial_expenses'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProfitAndLoss
     */
    'profit_before_tax'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProfitAndLoss
     */
    'tax'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProfitAndLoss
     */
    'profit_after_tax'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProfitAndLoss
     */
    'retained_profit'?: number | null;
}
/**
 * 
 * @export
 * @interface Ratios
 */
export interface Ratios {
    /**
     * 
     * @type {number}
     * @memberof Ratios
     */
    'return_on_capital_employed'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Ratios
     */
    'return_on_total_assets_employed'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Ratios
     */
    'return_on_net_assets_employed'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Ratios
     */
    'current_ratio'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Ratios
     */
    'liquidity_ratio_or_acid_test'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Ratios
     */
    'current_debt_ratio'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Ratios
     */
    'gearing'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Ratios
     */
    'equity_in_percentage'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Ratios
     */
    'total_debt_ratio'?: number | null;
}
/**
 * 
 * @export
 * @interface SearchCreditCheckCompany
 */
export interface SearchCreditCheckCompany {
    /**
     * 
     * @type {string}
     * @memberof SearchCreditCheckCompany
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SearchCreditCheckCompany
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SearchCreditCheckCompany
     */
    'registrationNumber': string;
    /**
     * 
     * @type {string}
     * @memberof SearchCreditCheckCompany
     */
    'vatNumber': string;
    /**
     * 
     * @type {SearchCreditCheckCompanyAddress}
     * @memberof SearchCreditCheckCompany
     */
    'address': SearchCreditCheckCompanyAddress;
    /**
     * 
     * @type {number}
     * @memberof SearchCreditCheckCompany
     */
    'olderReportId'?: number | null;
}
/**
 * 
 * @export
 * @interface SearchCreditCheckCompanyAddress
 */
export interface SearchCreditCheckCompanyAddress {
    /**
     * 
     * @type {string}
     * @memberof SearchCreditCheckCompanyAddress
     */
    'street': string;
    /**
     * 
     * @type {string}
     * @memberof SearchCreditCheckCompanyAddress
     */
    'houseNumber': string;
    /**
     * 
     * @type {string}
     * @memberof SearchCreditCheckCompanyAddress
     */
    'postalCode': string;
    /**
     * 
     * @type {string}
     * @memberof SearchCreditCheckCompanyAddress
     */
    'city': string;
}
/**
 * 
 * @export
 * @interface SearchCreditCheckCompanyOffsetConnection
 */
export interface SearchCreditCheckCompanyOffsetConnection {
    /**
     * 
     * @type {PageInfoType}
     * @memberof SearchCreditCheckCompanyOffsetConnection
     */
    'pageInfo': PageInfoType;
    /**
     * Total amount of records.
     * @type {number}
     * @memberof SearchCreditCheckCompanyOffsetConnection
     */
    'totalCount': number;
    /**
     * Array of nodes.
     * @type {Array<SearchCreditCheckCompany>}
     * @memberof SearchCreditCheckCompanyOffsetConnection
     */
    'nodes': Array<SearchCreditCheckCompany>;
}
/**
 * 
 * @export
 * @interface ShareCapitalStructure
 */
export interface ShareCapitalStructure {
    /**
     * 
     * @type {string}
     * @memberof ShareCapitalStructure
     */
    'issued_share_capital'?: string | null;
    /**
     * 
     * @type {ShareHolders}
     * @memberof ShareCapitalStructure
     */
    'share_holders'?: ShareHolders | null;
}
/**
 * 
 * @export
 * @interface ShareHolder
 */
export interface ShareHolder {
    /**
     * 
     * @type {string}
     * @memberof ShareHolder
     */
    'name'?: string | null;
    /**
     * 
     * @type {Address}
     * @memberof ShareHolder
     */
    'address'?: Address | null;
    /**
     * 
     * @type {string}
     * @memberof ShareHolder
     */
    'share_percent'?: string | null;
}
/**
 * 
 * @export
 * @interface ShareHolders
 */
export interface ShareHolders {
    /**
     * 
     * @type {Array<ShareHolder>}
     * @memberof ShareHolders
     */
    'share_holder'?: Array<ShareHolder> | null;
}
/**
 * 
 * @export
 * @interface SubsidiaryCompanies
 */
export interface SubsidiaryCompanies {
    /**
     * 
     * @type {Array<AffiliatedCompany>}
     * @memberof SubsidiaryCompanies
     */
    'subsidiary': Array<AffiliatedCompany>;
}
/**
 * 
 * @export
 * @interface TradingStyles
 */
export interface TradingStyles {
    /**
     * 
     * @type {Array<string>}
     * @memberof TradingStyles
     */
    'trading_name'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface UpdateCompany
 */
export interface UpdateCompany {
    /**
     * Name of the company.
     * @type {string}
     * @memberof UpdateCompany
     */
    'companyName': string | null;
    /**
     * Street name of the company.
     * @type {string}
     * @memberof UpdateCompany
     */
    'streetName': string | null;
    /**
     * House number of the company.
     * @type {string}
     * @memberof UpdateCompany
     */
    'houseNumber': string | null;
    /**
     * Zipcode of the company.
     * @type {string}
     * @memberof UpdateCompany
     */
    'zipcode': string | null;
    /**
     * City of the company.
     * @type {string}
     * @memberof UpdateCompany
     */
    'city': string | null;
    /**
     * Country code of the company (ISO 3166-1 alpha-2).
     * @type {string}
     * @memberof UpdateCompany
     */
    'countryCode': string | null;
    /**
     * CoC number of the company.
     * @type {string}
     * @memberof UpdateCompany
     */
    'kvkNumber'?: string | null;
    /**
     * IBAN of the company.
     * @type {string}
     * @memberof UpdateCompany
     */
    'bankAccountNumber': string | null;
    /**
     * Name of the bank account.
     * @type {string}
     * @memberof UpdateCompany
     */
    'bankAccountName': string | null;
    /**
     * Contact name to use on letters.
     * @type {string}
     * @memberof UpdateCompany
     */
    'letterContactName'?: string | null;
    /**
     * Email address to use on letters.
     * @type {string}
     * @memberof UpdateCompany
     */
    'letterEmail'?: string | null;
    /**
     * Phone number to use on letters.
     * @type {string}
     * @memberof UpdateCompany
     */
    'letterPhone'?: string | null;
    /**
     * CoC number to use on letters.
     * @type {string}
     * @memberof UpdateCompany
     */
    'letterKvkNumber'?: string | null;
    /**
     * Vat number to use on letters.
     * @type {string}
     * @memberof UpdateCompany
     */
    'letterVatNumber'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateCreditCase
 */
export interface UpdateCreditCase {
    /**
     * Required when isCompany = true.
     * @type {string}
     * @memberof UpdateCreditCase
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCreditCase
     */
    'firstname'?: string | null;
    /**
     * Required when isCompany = false.
     * @type {string}
     * @memberof UpdateCreditCase
     */
    'lastname'?: string | null;
    /**
     * Email of the debtor.
     * @type {string}
     * @memberof UpdateCreditCase
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCreditCase
     */
    'mobileNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCreditCase
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCreditCase
     */
    'isCompany'?: boolean;
    /**
     * Street of the debtor.
     * @type {string}
     * @memberof UpdateCreditCase
     */
    'streetName'?: string;
    /**
     * House number of the debtor.
     * @type {string}
     * @memberof UpdateCreditCase
     */
    'houseNumber'?: string;
    /**
     * Zipcode of the debtor.
     * @type {string}
     * @memberof UpdateCreditCase
     */
    'zipcode'?: string;
    /**
     * City of the debtor.
     * @type {string}
     * @memberof UpdateCreditCase
     */
    'city'?: string;
    /**
     * Country code of the debtor (ISO 3166-1 alpha-2).
     * @type {string}
     * @memberof UpdateCreditCase
     */
    'countryCode'?: string;
    /**
     * Language code to communicate in with the debtor.
     * @type {string}
     * @memberof UpdateCreditCase
     */
    'languageCode'?: UpdateCreditCaseLanguageCodeEnum;
    /**
     * ID of the administration this credit case belongs to.
     * @type {number}
     * @memberof UpdateCreditCase
     */
    'administrationId'?: number | null;
    /**
     * Should the credit case start with a pre-incasso.
     * @type {boolean}
     * @memberof UpdateCreditCase
     */
    'preIncasso'?: boolean;
    /**
     * Should the case be auto-forwarded to the selected bailiff.
     * @type {boolean}
     * @memberof UpdateCreditCase
     */
    'autoForward'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateCreditCase
     */
    'interestType'?: UpdateCreditCaseInterestTypeEnum;
}

export const UpdateCreditCaseLanguageCodeEnum = {
    Nl: 'nl',
    En: 'en'
} as const;

export type UpdateCreditCaseLanguageCodeEnum = typeof UpdateCreditCaseLanguageCodeEnum[keyof typeof UpdateCreditCaseLanguageCodeEnum];
export const UpdateCreditCaseInterestTypeEnum = {
    None: 'none',
    None2: 'None',
    Legal: 'legal',
    Legal2: 'Legal'
} as const;

export type UpdateCreditCaseInterestTypeEnum = typeof UpdateCreditCaseInterestTypeEnum[keyof typeof UpdateCreditCaseInterestTypeEnum];

/**
 * 
 * @export
 * @interface UpdateInvoice
 */
export interface UpdateInvoice {
    /**
     * Date of the invoice.
     * @type {string}
     * @memberof UpdateInvoice
     */
    'invoiceDate'?: string;
    /**
     * Due date of the invoice.
     * @type {string}
     * @memberof UpdateInvoice
     */
    'dueDate'?: string;
    /**
     * ID/Reference of the invoice.
     * @type {string}
     * @memberof UpdateInvoice
     */
    'invoiceId'?: string;
    /**
     * Amount of the invoice including taxes.
     * @type {number}
     * @memberof UpdateInvoice
     */
    'priceInclTax'?: number;
}
/**
 * 
 * @export
 * @interface Websites
 */
export interface Websites {
    /**
     * 
     * @type {Array<string>}
     * @memberof Websites
     */
    'website'?: Array<string> | null;
}

/**
 * CheckoutApi - axios parameter creator
 * @export
 */
export const CheckoutApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {string} provider 
         * @param {Cart} cart 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkout: async (provider: string, cart: Cart, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'provider' is not null or undefined
            assertParamExists('checkout', 'provider', provider)
            // verify required parameter 'cart' is not null or undefined
            assertParamExists('checkout', 'cart', cart)
            const localVarPath = `/v2.0/checkout/{provider}`
                .replace(`{${"provider"}}`, encodeURIComponent(String(provider)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cart, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CheckoutApi - functional programming interface
 * @export
 */
export const CheckoutApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CheckoutApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {string} provider 
         * @param {Cart} cart 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkout(provider: string, cart: Cart, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckoutComplete>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkout(provider, cart, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CheckoutApi - factory interface
 * @export
 */
export const CheckoutApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CheckoutApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {CheckoutApiCheckoutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkout(requestParameters: CheckoutApiCheckoutRequest, options?: AxiosRequestConfig): AxiosPromise<CheckoutComplete> {
            return localVarFp.checkout(requestParameters.provider, requestParameters.cart, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for checkout operation in CheckoutApi.
 * @export
 * @interface CheckoutApiCheckoutRequest
 */
export interface CheckoutApiCheckoutRequest {
    /**
     * 
     * @type {string}
     * @memberof CheckoutApiCheckout
     */
    readonly provider: string

    /**
     * 
     * @type {Cart}
     * @memberof CheckoutApiCheckout
     */
    readonly cart: Cart
}

/**
 * CheckoutApi - object-oriented interface
 * @export
 * @class CheckoutApi
 * @extends {BaseAPI}
 */
export class CheckoutApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {CheckoutApiCheckoutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public checkout(requestParameters: CheckoutApiCheckoutRequest, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).checkout(requestParameters.provider, requestParameters.cart, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CompanyApi - axios parameter creator
 * @export
 */
export const CompanyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Update your company
         * @summary 
         * @param {UpdateCompany} updateCompany 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyUpdate: async (updateCompany: UpdateCompany, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateCompany' is not null or undefined
            assertParamExists('companyUpdate', 'updateCompany', updateCompany)
            const localVarPath = `/v2.0/company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCompany, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyApi - functional programming interface
 * @export
 */
export const CompanyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyApiAxiosParamCreator(configuration)
    return {
        /**
         * Update your company
         * @summary 
         * @param {UpdateCompany} updateCompany 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyUpdate(updateCompany: UpdateCompany, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyUpdate(updateCompany, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompanyApi - factory interface
 * @export
 */
export const CompanyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyApiFp(configuration)
    return {
        /**
         * Update your company
         * @summary 
         * @param {CompanyApiCompanyUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyUpdate(requestParameters: CompanyApiCompanyUpdateRequest, options?: AxiosRequestConfig): AxiosPromise<Company> {
            return localVarFp.companyUpdate(requestParameters.updateCompany, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for companyUpdate operation in CompanyApi.
 * @export
 * @interface CompanyApiCompanyUpdateRequest
 */
export interface CompanyApiCompanyUpdateRequest {
    /**
     * 
     * @type {UpdateCompany}
     * @memberof CompanyApiCompanyUpdate
     */
    readonly updateCompany: UpdateCompany
}

/**
 * CompanyApi - object-oriented interface
 * @export
 * @class CompanyApi
 * @extends {BaseAPI}
 */
export class CompanyApi extends BaseAPI {
    /**
     * Update your company
     * @summary 
     * @param {CompanyApiCompanyUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyUpdate(requestParameters: CompanyApiCompanyUpdateRequest, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyUpdate(requestParameters.updateCompany, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CreditCasesApi - axios parameter creator
 * @export
 */
export const CreditCasesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {string} creditCaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseTimelineStepQueryMany: async (creditCaseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'creditCaseId' is not null or undefined
            assertParamExists('caseTimelineStepQueryMany', 'creditCaseId', creditCaseId)
            const localVarPath = `/v2.0/credit-cases/{creditCaseId}/timeline`
                .replace(`{${"creditCaseId"}}`, encodeURIComponent(String(creditCaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create one credit case.
         * @summary 
         * @param {CreateCreditCase} createCreditCase 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditCasesCreateOne: async (createCreditCase: CreateCreditCase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCreditCase' is not null or undefined
            assertParamExists('creditCasesCreateOne', 'createCreditCase', createCreditCase)
            const localVarPath = `/v2.0/credit-cases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCreditCase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete one draft credit case.
         * @summary 
         * @param {number} id Primary key of the recourse.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditCasesDeleteOne: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('creditCasesDeleteOne', 'id', id)
            const localVarPath = `/v2.0/credit-cases/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get one credit case.
         * @summary 
         * @param {number} id Primary key of the recourse.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditCasesFindById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('creditCasesFindById', 'id', id)
            const localVarPath = `/v2.0/credit-cases/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all credit cases.
         * @summary 
         * @param {number | null} [limit] 
         * @param {number | null} [offset] 
         * @param {string | null} [query] 
         * @param {CreditCasesQueryManyStateEnum} [state] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditCasesQueryMany: async (limit?: number | null, offset?: number | null, query?: string | null, state?: CreditCasesQueryManyStateEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2.0/credit-cases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update one draft credit case.
         * @summary 
         * @param {number} id Primary key of the recourse.
         * @param {UpdateCreditCase} updateCreditCase 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditCasesUpdateOne: async (id: number, updateCreditCase: UpdateCreditCase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('creditCasesUpdateOne', 'id', id)
            // verify required parameter 'updateCreditCase' is not null or undefined
            assertParamExists('creditCasesUpdateOne', 'updateCreditCase', updateCreditCase)
            const localVarPath = `/v2.0/credit-cases/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCreditCase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add one invoice to the draft credit case.
         * @summary 
         * @param {CreateInvoice} createInvoice 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesCreateOne: async (createInvoice: CreateInvoice, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createInvoice' is not null or undefined
            assertParamExists('invoicesCreateOne', 'createInvoice', createInvoice)
            const localVarPath = `/v2.0/invoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createInvoice, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete one invoice of a draft credit case.
         * @summary 
         * @param {number} id Primary key of the recourse.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesDeleteOne: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('invoicesDeleteOne', 'id', id)
            const localVarPath = `/v2.0/invoices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update one invoice of a draft credit case.
         * @summary 
         * @param {number} id Primary key of the recourse.
         * @param {UpdateInvoice} updateInvoice 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesUpdateOne: async (id: number, updateInvoice: UpdateInvoice, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('invoicesUpdateOne', 'id', id)
            // verify required parameter 'updateInvoice' is not null or undefined
            assertParamExists('invoicesUpdateOne', 'updateInvoice', updateInvoice)
            const localVarPath = `/v2.0/invoices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateInvoice, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CreditCasesApi - functional programming interface
 * @export
 */
export const CreditCasesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CreditCasesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {string} creditCaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseTimelineStepQueryMany(creditCaseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CaseTimelineStep>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseTimelineStepQueryMany(creditCaseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create one credit case.
         * @summary 
         * @param {CreateCreditCase} createCreditCase 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async creditCasesCreateOne(createCreditCase: CreateCreditCase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreditCase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.creditCasesCreateOne(createCreditCase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete one draft credit case.
         * @summary 
         * @param {number} id Primary key of the recourse.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async creditCasesDeleteOne(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreditCase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.creditCasesDeleteOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get one credit case.
         * @summary 
         * @param {number} id Primary key of the recourse.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async creditCasesFindById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindCreditCase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.creditCasesFindById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all credit cases.
         * @summary 
         * @param {number | null} [limit] 
         * @param {number | null} [offset] 
         * @param {string | null} [query] 
         * @param {CreditCasesQueryManyStateEnum} [state] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async creditCasesQueryMany(limit?: number | null, offset?: number | null, query?: string | null, state?: CreditCasesQueryManyStateEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreditCaseConnection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.creditCasesQueryMany(limit, offset, query, state, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update one draft credit case.
         * @summary 
         * @param {number} id Primary key of the recourse.
         * @param {UpdateCreditCase} updateCreditCase 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async creditCasesUpdateOne(id: number, updateCreditCase: UpdateCreditCase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreditCase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.creditCasesUpdateOne(id, updateCreditCase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add one invoice to the draft credit case.
         * @summary 
         * @param {CreateInvoice} createInvoice 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invoicesCreateOne(createInvoice: CreateInvoice, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Invoice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invoicesCreateOne(createInvoice, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete one invoice of a draft credit case.
         * @summary 
         * @param {number} id Primary key of the recourse.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invoicesDeleteOne(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Invoice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invoicesDeleteOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update one invoice of a draft credit case.
         * @summary 
         * @param {number} id Primary key of the recourse.
         * @param {UpdateInvoice} updateInvoice 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invoicesUpdateOne(id: number, updateInvoice: UpdateInvoice, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Invoice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invoicesUpdateOne(id, updateInvoice, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CreditCasesApi - factory interface
 * @export
 */
export const CreditCasesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CreditCasesApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {CreditCasesApiCaseTimelineStepQueryManyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseTimelineStepQueryMany(requestParameters: CreditCasesApiCaseTimelineStepQueryManyRequest, options?: AxiosRequestConfig): AxiosPromise<Array<CaseTimelineStep>> {
            return localVarFp.caseTimelineStepQueryMany(requestParameters.creditCaseId, options).then((request) => request(axios, basePath));
        },
        /**
         * Create one credit case.
         * @summary 
         * @param {CreditCasesApiCreditCasesCreateOneRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditCasesCreateOne(requestParameters: CreditCasesApiCreditCasesCreateOneRequest, options?: AxiosRequestConfig): AxiosPromise<CreditCase> {
            return localVarFp.creditCasesCreateOne(requestParameters.createCreditCase, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete one draft credit case.
         * @summary 
         * @param {CreditCasesApiCreditCasesDeleteOneRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditCasesDeleteOne(requestParameters: CreditCasesApiCreditCasesDeleteOneRequest, options?: AxiosRequestConfig): AxiosPromise<CreditCase> {
            return localVarFp.creditCasesDeleteOne(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get one credit case.
         * @summary 
         * @param {CreditCasesApiCreditCasesFindByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditCasesFindById(requestParameters: CreditCasesApiCreditCasesFindByIdRequest, options?: AxiosRequestConfig): AxiosPromise<FindCreditCase> {
            return localVarFp.creditCasesFindById(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all credit cases.
         * @summary 
         * @param {CreditCasesApiCreditCasesQueryManyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditCasesQueryMany(requestParameters: CreditCasesApiCreditCasesQueryManyRequest = {}, options?: AxiosRequestConfig): AxiosPromise<CreditCaseConnection> {
            return localVarFp.creditCasesQueryMany(requestParameters.limit, requestParameters.offset, requestParameters.query, requestParameters.state, options).then((request) => request(axios, basePath));
        },
        /**
         * Update one draft credit case.
         * @summary 
         * @param {CreditCasesApiCreditCasesUpdateOneRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditCasesUpdateOne(requestParameters: CreditCasesApiCreditCasesUpdateOneRequest, options?: AxiosRequestConfig): AxiosPromise<CreditCase> {
            return localVarFp.creditCasesUpdateOne(requestParameters.id, requestParameters.updateCreditCase, options).then((request) => request(axios, basePath));
        },
        /**
         * Add one invoice to the draft credit case.
         * @summary 
         * @param {CreditCasesApiInvoicesCreateOneRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesCreateOne(requestParameters: CreditCasesApiInvoicesCreateOneRequest, options?: AxiosRequestConfig): AxiosPromise<Invoice> {
            return localVarFp.invoicesCreateOne(requestParameters.createInvoice, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete one invoice of a draft credit case.
         * @summary 
         * @param {CreditCasesApiInvoicesDeleteOneRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesDeleteOne(requestParameters: CreditCasesApiInvoicesDeleteOneRequest, options?: AxiosRequestConfig): AxiosPromise<Invoice> {
            return localVarFp.invoicesDeleteOne(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Update one invoice of a draft credit case.
         * @summary 
         * @param {CreditCasesApiInvoicesUpdateOneRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesUpdateOne(requestParameters: CreditCasesApiInvoicesUpdateOneRequest, options?: AxiosRequestConfig): AxiosPromise<Invoice> {
            return localVarFp.invoicesUpdateOne(requestParameters.id, requestParameters.updateInvoice, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for caseTimelineStepQueryMany operation in CreditCasesApi.
 * @export
 * @interface CreditCasesApiCaseTimelineStepQueryManyRequest
 */
export interface CreditCasesApiCaseTimelineStepQueryManyRequest {
    /**
     * 
     * @type {string}
     * @memberof CreditCasesApiCaseTimelineStepQueryMany
     */
    readonly creditCaseId: string
}

/**
 * Request parameters for creditCasesCreateOne operation in CreditCasesApi.
 * @export
 * @interface CreditCasesApiCreditCasesCreateOneRequest
 */
export interface CreditCasesApiCreditCasesCreateOneRequest {
    /**
     * 
     * @type {CreateCreditCase}
     * @memberof CreditCasesApiCreditCasesCreateOne
     */
    readonly createCreditCase: CreateCreditCase
}

/**
 * Request parameters for creditCasesDeleteOne operation in CreditCasesApi.
 * @export
 * @interface CreditCasesApiCreditCasesDeleteOneRequest
 */
export interface CreditCasesApiCreditCasesDeleteOneRequest {
    /**
     * Primary key of the recourse.
     * @type {number}
     * @memberof CreditCasesApiCreditCasesDeleteOne
     */
    readonly id: number
}

/**
 * Request parameters for creditCasesFindById operation in CreditCasesApi.
 * @export
 * @interface CreditCasesApiCreditCasesFindByIdRequest
 */
export interface CreditCasesApiCreditCasesFindByIdRequest {
    /**
     * Primary key of the recourse.
     * @type {number}
     * @memberof CreditCasesApiCreditCasesFindById
     */
    readonly id: number
}

/**
 * Request parameters for creditCasesQueryMany operation in CreditCasesApi.
 * @export
 * @interface CreditCasesApiCreditCasesQueryManyRequest
 */
export interface CreditCasesApiCreditCasesQueryManyRequest {
    /**
     * 
     * @type {number}
     * @memberof CreditCasesApiCreditCasesQueryMany
     */
    readonly limit?: number | null

    /**
     * 
     * @type {number}
     * @memberof CreditCasesApiCreditCasesQueryMany
     */
    readonly offset?: number | null

    /**
     * 
     * @type {string}
     * @memberof CreditCasesApiCreditCasesQueryMany
     */
    readonly query?: string | null

    /**
     * 
     * @type {'draft' | 'paused' | 'forwarded' | 'closed' | 'finished' | 'paid' | 'open'}
     * @memberof CreditCasesApiCreditCasesQueryMany
     */
    readonly state?: CreditCasesQueryManyStateEnum
}

/**
 * Request parameters for creditCasesUpdateOne operation in CreditCasesApi.
 * @export
 * @interface CreditCasesApiCreditCasesUpdateOneRequest
 */
export interface CreditCasesApiCreditCasesUpdateOneRequest {
    /**
     * Primary key of the recourse.
     * @type {number}
     * @memberof CreditCasesApiCreditCasesUpdateOne
     */
    readonly id: number

    /**
     * 
     * @type {UpdateCreditCase}
     * @memberof CreditCasesApiCreditCasesUpdateOne
     */
    readonly updateCreditCase: UpdateCreditCase
}

/**
 * Request parameters for invoicesCreateOne operation in CreditCasesApi.
 * @export
 * @interface CreditCasesApiInvoicesCreateOneRequest
 */
export interface CreditCasesApiInvoicesCreateOneRequest {
    /**
     * 
     * @type {CreateInvoice}
     * @memberof CreditCasesApiInvoicesCreateOne
     */
    readonly createInvoice: CreateInvoice
}

/**
 * Request parameters for invoicesDeleteOne operation in CreditCasesApi.
 * @export
 * @interface CreditCasesApiInvoicesDeleteOneRequest
 */
export interface CreditCasesApiInvoicesDeleteOneRequest {
    /**
     * Primary key of the recourse.
     * @type {number}
     * @memberof CreditCasesApiInvoicesDeleteOne
     */
    readonly id: number
}

/**
 * Request parameters for invoicesUpdateOne operation in CreditCasesApi.
 * @export
 * @interface CreditCasesApiInvoicesUpdateOneRequest
 */
export interface CreditCasesApiInvoicesUpdateOneRequest {
    /**
     * Primary key of the recourse.
     * @type {number}
     * @memberof CreditCasesApiInvoicesUpdateOne
     */
    readonly id: number

    /**
     * 
     * @type {UpdateInvoice}
     * @memberof CreditCasesApiInvoicesUpdateOne
     */
    readonly updateInvoice: UpdateInvoice
}

/**
 * CreditCasesApi - object-oriented interface
 * @export
 * @class CreditCasesApi
 * @extends {BaseAPI}
 */
export class CreditCasesApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {CreditCasesApiCaseTimelineStepQueryManyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCasesApi
     */
    public caseTimelineStepQueryMany(requestParameters: CreditCasesApiCaseTimelineStepQueryManyRequest, options?: AxiosRequestConfig) {
        return CreditCasesApiFp(this.configuration).caseTimelineStepQueryMany(requestParameters.creditCaseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create one credit case.
     * @summary 
     * @param {CreditCasesApiCreditCasesCreateOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCasesApi
     */
    public creditCasesCreateOne(requestParameters: CreditCasesApiCreditCasesCreateOneRequest, options?: AxiosRequestConfig) {
        return CreditCasesApiFp(this.configuration).creditCasesCreateOne(requestParameters.createCreditCase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete one draft credit case.
     * @summary 
     * @param {CreditCasesApiCreditCasesDeleteOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCasesApi
     */
    public creditCasesDeleteOne(requestParameters: CreditCasesApiCreditCasesDeleteOneRequest, options?: AxiosRequestConfig) {
        return CreditCasesApiFp(this.configuration).creditCasesDeleteOne(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get one credit case.
     * @summary 
     * @param {CreditCasesApiCreditCasesFindByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCasesApi
     */
    public creditCasesFindById(requestParameters: CreditCasesApiCreditCasesFindByIdRequest, options?: AxiosRequestConfig) {
        return CreditCasesApiFp(this.configuration).creditCasesFindById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all credit cases.
     * @summary 
     * @param {CreditCasesApiCreditCasesQueryManyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCasesApi
     */
    public creditCasesQueryMany(requestParameters: CreditCasesApiCreditCasesQueryManyRequest = {}, options?: AxiosRequestConfig) {
        return CreditCasesApiFp(this.configuration).creditCasesQueryMany(requestParameters.limit, requestParameters.offset, requestParameters.query, requestParameters.state, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update one draft credit case.
     * @summary 
     * @param {CreditCasesApiCreditCasesUpdateOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCasesApi
     */
    public creditCasesUpdateOne(requestParameters: CreditCasesApiCreditCasesUpdateOneRequest, options?: AxiosRequestConfig) {
        return CreditCasesApiFp(this.configuration).creditCasesUpdateOne(requestParameters.id, requestParameters.updateCreditCase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add one invoice to the draft credit case.
     * @summary 
     * @param {CreditCasesApiInvoicesCreateOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCasesApi
     */
    public invoicesCreateOne(requestParameters: CreditCasesApiInvoicesCreateOneRequest, options?: AxiosRequestConfig) {
        return CreditCasesApiFp(this.configuration).invoicesCreateOne(requestParameters.createInvoice, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete one invoice of a draft credit case.
     * @summary 
     * @param {CreditCasesApiInvoicesDeleteOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCasesApi
     */
    public invoicesDeleteOne(requestParameters: CreditCasesApiInvoicesDeleteOneRequest, options?: AxiosRequestConfig) {
        return CreditCasesApiFp(this.configuration).invoicesDeleteOne(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update one invoice of a draft credit case.
     * @summary 
     * @param {CreditCasesApiInvoicesUpdateOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCasesApi
     */
    public invoicesUpdateOne(requestParameters: CreditCasesApiInvoicesUpdateOneRequest, options?: AxiosRequestConfig) {
        return CreditCasesApiFp(this.configuration).invoicesUpdateOne(requestParameters.id, requestParameters.updateInvoice, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const CreditCasesQueryManyStateEnum = {
    Draft: 'draft',
    Paused: 'paused',
    Forwarded: 'forwarded',
    Closed: 'closed',
    Finished: 'finished',
    Paid: 'paid',
    Open: 'open'
} as const;
export type CreditCasesQueryManyStateEnum = typeof CreditCasesQueryManyStateEnum[keyof typeof CreditCasesQueryManyStateEnum];


/**
 * CreditChecksApi - axios parameter creator
 * @export
 */
export const CreditChecksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditCheckSearch: async (query: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'query' is not null or undefined
            assertParamExists('creditCheckSearch', 'query', query)
            const localVarPath = `/v2.0/credit-checks/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get one credit check.
         * @summary 
         * @param {number} id Primary key of the recourse.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditChecksFindById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('creditChecksFindById', 'id', id)
            const localVarPath = `/v2.0/credit-checks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all credit checks.
         * @summary 
         * @param {number | null} [limit] 
         * @param {number | null} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditChecksQueryMany: async (limit?: number | null, offset?: number | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2.0/credit-checks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CreditChecksApi - functional programming interface
 * @export
 */
export const CreditChecksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CreditChecksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async creditCheckSearch(query: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchCreditCheckCompanyOffsetConnection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.creditCheckSearch(query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get one credit check.
         * @summary 
         * @param {number} id Primary key of the recourse.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async creditChecksFindById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreditCheck>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.creditChecksFindById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all credit checks.
         * @summary 
         * @param {number | null} [limit] 
         * @param {number | null} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async creditChecksQueryMany(limit?: number | null, offset?: number | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreditCheckConnection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.creditChecksQueryMany(limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CreditChecksApi - factory interface
 * @export
 */
export const CreditChecksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CreditChecksApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {CreditChecksApiCreditCheckSearchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditCheckSearch(requestParameters: CreditChecksApiCreditCheckSearchRequest, options?: AxiosRequestConfig): AxiosPromise<SearchCreditCheckCompanyOffsetConnection> {
            return localVarFp.creditCheckSearch(requestParameters.query, options).then((request) => request(axios, basePath));
        },
        /**
         * Get one credit check.
         * @summary 
         * @param {CreditChecksApiCreditChecksFindByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditChecksFindById(requestParameters: CreditChecksApiCreditChecksFindByIdRequest, options?: AxiosRequestConfig): AxiosPromise<CreditCheck> {
            return localVarFp.creditChecksFindById(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all credit checks.
         * @summary 
         * @param {CreditChecksApiCreditChecksQueryManyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditChecksQueryMany(requestParameters: CreditChecksApiCreditChecksQueryManyRequest = {}, options?: AxiosRequestConfig): AxiosPromise<CreditCheckConnection> {
            return localVarFp.creditChecksQueryMany(requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for creditCheckSearch operation in CreditChecksApi.
 * @export
 * @interface CreditChecksApiCreditCheckSearchRequest
 */
export interface CreditChecksApiCreditCheckSearchRequest {
    /**
     * 
     * @type {string}
     * @memberof CreditChecksApiCreditCheckSearch
     */
    readonly query: string
}

/**
 * Request parameters for creditChecksFindById operation in CreditChecksApi.
 * @export
 * @interface CreditChecksApiCreditChecksFindByIdRequest
 */
export interface CreditChecksApiCreditChecksFindByIdRequest {
    /**
     * Primary key of the recourse.
     * @type {number}
     * @memberof CreditChecksApiCreditChecksFindById
     */
    readonly id: number
}

/**
 * Request parameters for creditChecksQueryMany operation in CreditChecksApi.
 * @export
 * @interface CreditChecksApiCreditChecksQueryManyRequest
 */
export interface CreditChecksApiCreditChecksQueryManyRequest {
    /**
     * 
     * @type {number}
     * @memberof CreditChecksApiCreditChecksQueryMany
     */
    readonly limit?: number | null

    /**
     * 
     * @type {number}
     * @memberof CreditChecksApiCreditChecksQueryMany
     */
    readonly offset?: number | null
}

/**
 * CreditChecksApi - object-oriented interface
 * @export
 * @class CreditChecksApi
 * @extends {BaseAPI}
 */
export class CreditChecksApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {CreditChecksApiCreditCheckSearchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditChecksApi
     */
    public creditCheckSearch(requestParameters: CreditChecksApiCreditCheckSearchRequest, options?: AxiosRequestConfig) {
        return CreditChecksApiFp(this.configuration).creditCheckSearch(requestParameters.query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get one credit check.
     * @summary 
     * @param {CreditChecksApiCreditChecksFindByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditChecksApi
     */
    public creditChecksFindById(requestParameters: CreditChecksApiCreditChecksFindByIdRequest, options?: AxiosRequestConfig) {
        return CreditChecksApiFp(this.configuration).creditChecksFindById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all credit checks.
     * @summary 
     * @param {CreditChecksApiCreditChecksQueryManyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditChecksApi
     */
    public creditChecksQueryMany(requestParameters: CreditChecksApiCreditChecksQueryManyRequest = {}, options?: AxiosRequestConfig) {
        return CreditChecksApiFp(this.configuration).creditChecksQueryMany(requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DashboardApi - axios parameter creator
 * @export
 */
export const DashboardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stats: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2.0/dashboard/stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DashboardApi - functional programming interface
 * @export
 */
export const DashboardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DashboardApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stats(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardStats>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stats(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DashboardApi - factory interface
 * @export
 */
export const DashboardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DashboardApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stats(options?: AxiosRequestConfig): AxiosPromise<DashboardStats> {
            return localVarFp.stats(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DashboardApi - object-oriented interface
 * @export
 * @class DashboardApi
 * @extends {BaseAPI}
 */
export class DashboardApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public stats(options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).stats(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DebtorApi - axios parameter creator
 * @export
 */
export const DebtorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {string} hash 
         * @param {CreateDebtorMessage} createDebtorMessage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditCaseMessageCreateOne: async (hash: string, createDebtorMessage: CreateDebtorMessage, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hash' is not null or undefined
            assertParamExists('creditCaseMessageCreateOne', 'hash', hash)
            // verify required parameter 'createDebtorMessage' is not null or undefined
            assertParamExists('creditCaseMessageCreateOne', 'createDebtorMessage', createDebtorMessage)
            const localVarPath = `/v2.0/debtor/credit-cases/{hash}/messages`
                .replace(`{${"hash"}}`, encodeURIComponent(String(hash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDebtorMessage, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditCasesFindByHash: async (hash: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hash' is not null or undefined
            assertParamExists('creditCasesFindByHash', 'hash', hash)
            const localVarPath = `/v2.0/debtor/credit-cases/{hash}`
                .replace(`{${"hash"}}`, encodeURIComponent(String(hash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {LoginWithPin} loginWithPin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginWithPin: async (loginWithPin: LoginWithPin, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginWithPin' is not null or undefined
            assertParamExists('loginWithPin', 'loginWithPin', loginWithPin)
            const localVarPath = `/v2.0/debtor/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginWithPin, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DebtorApi - functional programming interface
 * @export
 */
export const DebtorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DebtorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {string} hash 
         * @param {CreateDebtorMessage} createDebtorMessage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async creditCaseMessageCreateOne(hash: string, createDebtorMessage: CreateDebtorMessage, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseTimelineStep>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.creditCaseMessageCreateOne(hash, createDebtorMessage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async creditCasesFindByHash(hash: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DebtorCreditCase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.creditCasesFindByHash(hash, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {LoginWithPin} loginWithPin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginWithPin(loginWithPin: LoginWithPin, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginWithPin(loginWithPin, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DebtorApi - factory interface
 * @export
 */
export const DebtorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DebtorApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {DebtorApiCreditCaseMessageCreateOneRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditCaseMessageCreateOne(requestParameters: DebtorApiCreditCaseMessageCreateOneRequest, options?: AxiosRequestConfig): AxiosPromise<CaseTimelineStep> {
            return localVarFp.creditCaseMessageCreateOne(requestParameters.hash, requestParameters.createDebtorMessage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {DebtorApiCreditCasesFindByHashRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditCasesFindByHash(requestParameters: DebtorApiCreditCasesFindByHashRequest, options?: AxiosRequestConfig): AxiosPromise<DebtorCreditCase> {
            return localVarFp.creditCasesFindByHash(requestParameters.hash, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {DebtorApiLoginWithPinRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginWithPin(requestParameters: DebtorApiLoginWithPinRequest, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.loginWithPin(requestParameters.loginWithPin, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for creditCaseMessageCreateOne operation in DebtorApi.
 * @export
 * @interface DebtorApiCreditCaseMessageCreateOneRequest
 */
export interface DebtorApiCreditCaseMessageCreateOneRequest {
    /**
     * 
     * @type {string}
     * @memberof DebtorApiCreditCaseMessageCreateOne
     */
    readonly hash: string

    /**
     * 
     * @type {CreateDebtorMessage}
     * @memberof DebtorApiCreditCaseMessageCreateOne
     */
    readonly createDebtorMessage: CreateDebtorMessage
}

/**
 * Request parameters for creditCasesFindByHash operation in DebtorApi.
 * @export
 * @interface DebtorApiCreditCasesFindByHashRequest
 */
export interface DebtorApiCreditCasesFindByHashRequest {
    /**
     * 
     * @type {string}
     * @memberof DebtorApiCreditCasesFindByHash
     */
    readonly hash: string
}

/**
 * Request parameters for loginWithPin operation in DebtorApi.
 * @export
 * @interface DebtorApiLoginWithPinRequest
 */
export interface DebtorApiLoginWithPinRequest {
    /**
     * 
     * @type {LoginWithPin}
     * @memberof DebtorApiLoginWithPin
     */
    readonly loginWithPin: LoginWithPin
}

/**
 * DebtorApi - object-oriented interface
 * @export
 * @class DebtorApi
 * @extends {BaseAPI}
 */
export class DebtorApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {DebtorApiCreditCaseMessageCreateOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DebtorApi
     */
    public creditCaseMessageCreateOne(requestParameters: DebtorApiCreditCaseMessageCreateOneRequest, options?: AxiosRequestConfig) {
        return DebtorApiFp(this.configuration).creditCaseMessageCreateOne(requestParameters.hash, requestParameters.createDebtorMessage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {DebtorApiCreditCasesFindByHashRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DebtorApi
     */
    public creditCasesFindByHash(requestParameters: DebtorApiCreditCasesFindByHashRequest, options?: AxiosRequestConfig) {
        return DebtorApiFp(this.configuration).creditCasesFindByHash(requestParameters.hash, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {DebtorApiLoginWithPinRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DebtorApi
     */
    public loginWithPin(requestParameters: DebtorApiLoginWithPinRequest, options?: AxiosRequestConfig) {
        return DebtorApiFp(this.configuration).loginWithPin(requestParameters.loginWithPin, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerGetHealth: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2.0/_check/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerHeadHealth: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2.0/_check/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthControllerGetHealth(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthControllerGetHealth(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthControllerHeadHealth(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthControllerHeadHealth(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerGetHealth(options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.healthControllerGetHealth(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerHeadHealth(options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.healthControllerHeadHealth(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public healthControllerGetHealth(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).healthControllerGetHealth(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public healthControllerHeadHealth(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).healthControllerHeadHealth(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OAuthApi - axios parameter creator
 * @export
 */
export const OAuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (loginDto: LoginDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginDto' is not null or undefined
            assertParamExists('login', 'loginDto', loginDto)
            const localVarPath = `/v2.0/oauth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2.0/oauth/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        me: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2.0/oauth/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthControllerAuthorize: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2.0/oauth/authorize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OAuthApi - functional programming interface
 * @export
 */
export const OAuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OAuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(loginDto: LoginDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(loginDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logout(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logout(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async me(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Me>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.me(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async oauthControllerAuthorize(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.oauthControllerAuthorize(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OAuthApi - factory interface
 * @export
 */
export const OAuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OAuthApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {OAuthApiLoginRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(requestParameters: OAuthApiLoginRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.login(requestParameters.loginDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.logout(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        me(options?: AxiosRequestConfig): AxiosPromise<Me> {
            return localVarFp.me(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthControllerAuthorize(options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.oauthControllerAuthorize(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for login operation in OAuthApi.
 * @export
 * @interface OAuthApiLoginRequest
 */
export interface OAuthApiLoginRequest {
    /**
     * 
     * @type {LoginDto}
     * @memberof OAuthApiLogin
     */
    readonly loginDto: LoginDto
}

/**
 * OAuthApi - object-oriented interface
 * @export
 * @class OAuthApi
 * @extends {BaseAPI}
 */
export class OAuthApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {OAuthApiLoginRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuthApi
     */
    public login(requestParameters: OAuthApiLoginRequest, options?: AxiosRequestConfig) {
        return OAuthApiFp(this.configuration).login(requestParameters.loginDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuthApi
     */
    public logout(options?: AxiosRequestConfig) {
        return OAuthApiFp(this.configuration).logout(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuthApi
     */
    public me(options?: AxiosRequestConfig) {
        return OAuthApiFp(this.configuration).me(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuthApi
     */
    public oauthControllerAuthorize(options?: AxiosRequestConfig) {
        return OAuthApiFp(this.configuration).oauthControllerAuthorize(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerForgotPassword: async (body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('userControllerForgotPassword', 'body', body)
            const localVarPath = `/v2.0/user/forgot_password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerForgotPassword(body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerForgotPassword(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @param {UsersApiUserControllerForgotPasswordRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerForgotPassword(requestParameters: UsersApiUserControllerForgotPasswordRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.userControllerForgotPassword(requestParameters.body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for userControllerForgotPassword operation in UsersApi.
 * @export
 * @interface UsersApiUserControllerForgotPasswordRequest
 */
export interface UsersApiUserControllerForgotPasswordRequest {
    /**
     * 
     * @type {object}
     * @memberof UsersApiUserControllerForgotPassword
     */
    readonly body: object
}

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @param {UsersApiUserControllerForgotPasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerForgotPassword(requestParameters: UsersApiUserControllerForgotPasswordRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).userControllerForgotPassword(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }
}




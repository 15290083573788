import {
  CheckoutApi,
  CompanyApi,
  Configuration,
  CreditCasesApi,
  CreditChecksApi,
  DashboardApi,
  DebtorApi,
  OAuthApi
} from './open-api'

const apiConfiguration = new Configuration({
  basePath: process.env.NODE_ENV === 'development' ? 'http://oi.local:9500' : undefined,
  baseOptions: {
    withCredentials: true
  }
})

export const creditCaseAPi = new CreditCasesApi(apiConfiguration)
export const creditChecksApi = new CreditChecksApi(apiConfiguration)
export const oauthApi = new OAuthApi(apiConfiguration)
export const companyApi = new CompanyApi(apiConfiguration)
export const checkoutApi = new CheckoutApi(apiConfiguration)
export const dashboardApi = new DashboardApi(apiConfiguration)
export const debtorapi = new DebtorApi(apiConfiguration)
